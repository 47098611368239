.PushDropDown {
  display: flex;
  flex-direction: column;
  background-color: $light_component_background;
  flex: 0;
  flex-basis: 35px;
  min-height: 35px;
  animation: 0.3s all;

  .PushDropDown--Elements--Container {
    height: 0;
    overflow-y: hidden;
    display: none;
    background: $white;
    transition: width 0.3s 0.5s;
    padding-right: 10px;
  }

  &.PushDropDown--open {
    height: auto;
    flex: 0;
    flex-basis: auto;

    .PushDropDown--Elements--Container {
      overflow-y: auto;
      height: auto;
      background: $white;
      display: block;
    }

    .PushDropDownFilter {
      flex-basis: 40px;
      min-height: 40px;

      &::before {
        content: " ";
      }
    }
  }

  .PushDropDown__label {
    flex-basis: 35px;
    min-height: 35px;
    line-height: 35px;
    cursor: pointer;
    background-color: $light_component_background;
    z-index: 900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .FontStyle--Bold;
    font-size: 18px;
    padding: 8px 10px;

    label {
      flex: 1;
    }

    svg {
      transform: rotate(-180deg);
    }
  }
}

.PushDropDown__label-counter {
  border-radius: 50%;
  background-color: $red;
  color: $white;
  height: 15px;
  width: 15px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  margin-right: 5px;

  &::after {
    content: " ";
    height: 15px;
    background: $red;
    width: 1px;
    display: block;
    position: absolute;
    left: 10px;
  }
}

.PushDropDown--open {
  .PushDropDown__label svg {
    transform: rotate(0);
  }
}

.PushDropDown--Elements--Container--empty {
  font-size: 11px;
  margin: 0 0 0 5px;
  min-height: 20px;
  flex-basis: 20px;
}

.PushDropDownFilter {
  background: $white;
  display: flex;
  height: 0;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 20px;

  &::before {
    height: 60px;
    background: $red;
    width: 1px;
    display: block;
    position: absolute;
    left: 10px;
  }
}

.PushDropDownFilter__Part {
  border-bottom: 1px solid $black;
  padding: 5px 0;
  flex: 1;
  text-align: center;
  font-size: 14px;
  position: relative;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}

.PushDropDownFilter__Part--active {
  font-weight: bold;

  &::after {
    content: " ";
    width: 100%;
    height: 3px;
    background: $black;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
