.UpdateShareOfDraft {
  .Share__Element {
    max-width: calc(50% - 3px);
    min-width: calc(50% - 3px);
  }
}

.Share__Element.old:nth-child(even) {
  margin-right: $paddingXSmall;
  margin-left: 0;
}

.Share__Element.old:nth-child(odd) {
  margin-left: $paddingXSmall;
  margin-right: 0;
}

.Share__Element {
  display: inline-flex;
  flex-direction: row;
  max-width: 250px;
  min-width: 100px;
  height: 30px;
  border-radius: $borderRadius;
  margin-right: $paddingSmall;
  margin-bottom: $paddingNormal;
  padding: $paddingXSmall $paddingSmall;
  border: 1px solid $gray;

  .Share__Element__Delete__Header,
  .Share__Element__Header {
    width: calc(100% - 20px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 24px;
  }

  .Share__Element__Delete__Header {
    display: none;
  }

  .Share__Element__Undo__Remove {
    display: none;
  }

  .Share__Element__Remove {
    position: relative;
    text-align: center;
    font-size: 10px;
    width: 15px;
    height: 15px;
    line-height: 16px;
    margin-top: 4px;
    margin-left: 1px;
    cursor: pointer;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      border: 1px solid $black;
      left: 0;
      top: 0;
      z-index: 10;
    }
  }

  &.added {
    margin-right: $paddingSmall;
  }

  &.delete {
    border-color: $red;
    color: $red;
    font-weight: bold;

    .Share__Element__Header {
      display: none;
    }

    .Share__Element__Delete__Header {
      display: block;
    }

    .Share__Element__Remove {
      display: none;
    }

    .Share__Element__Undo__Remove {
      display: block;
      font-size: 10px;
      color: $black;
      line-height: 24px;
      font-weight: normal;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}