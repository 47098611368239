.MagicDragElement {
  width: 100%;
  height: 30px;
  cursor: move;
  color: $black;
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #B2C2CC;
  border-radius: $borderRadius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}