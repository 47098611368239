.MastersModule {}

.MastersModule__list {}

.MastersModule__listitem,
.NextScheduleModule__listitem {
  background-color: white;
  cursor: pointer;

  .darkmode & {
    background: transparent;
    border: 1px solid #535556;
    color: $white;
  }

  .contextmenu {
    transform: translate3d(12px, -45px, 0);
  }
}

.MastersModule {
  .MastersModule__listitem,
  .NextScheduleModule__listitem {
    .contextmenu {
      @media screen and (max-width: 1280px) {
        transform: translate3d(-240px, -45px, 0);
        border: 1px solid gray;
        border-radius: 6px;

        .arrow {
          transform: scale(-1);
          left: 198px;
        }
      }
    }
  }
}

.MastersModule__listitem {
  position: relative;
  border-radius: $borderRadius;
  margin: 0 0 10px;
  text-decoration: none;
  color: $black;
  display: block;
  background: $white;

  &:hover {
    .MastersModule__listitem-date,
    .MastersModule__listitem-header {
      color: #8395A1;
    }
  }
}

.MastersModule__listitem-subheader {
  background: $light_component_background;
  text-align: center;
  padding: 12px 0;

  .darkmode & {
    color: $black;
  }
}

.MastersModule__listitem-header {
  padding: 5px 10px 0;
  @extend .FontStyle--Bold;
  display: block;
}

.MastersModule__listitem-date {
  padding: 0 10px 5px;
  display: block;
}

.MastersModule__listitem-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  right: 10px;
  bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #DCE1E5;
    border-radius: 100%;
  }

  &:hover {
    &:after {
      border: 1px solid $black;
    }
  }
}

.MastersModule__listitem-contexttrigger {
  float: right;
  font-size: 33px;
  font-weight: bold;
  width: 20px;
  display: block;
  margin: 5px;
  color: $light_gray;
  cursor: pointer;

  &.active,
  &:hover {
    color: $black;
  }
}