.SchedulerTopVersion,
.SchedulerTopVersionTimeline {
  position: relative;
  display: flex;
  width: 100%;
  flex-basis: 45px;
  border-bottom: 1px solid white;
  margin-left: 0;
  justify-content: center;
  align-items: center;
  // min-height: 45px;
  &.open {
    .SchedulerTopVersionTimeline__list,
    .SchedulerTopVersion__list {
      height: calc(100vh - 105px);
      min-height: 120px;
    }

    .SchedulerTopVersionTimeline__list__Content__Container,
    .SchedulerTopVersion__list__Content__Container {
      transform: translate3d(0, 0, 0);
    }
  }
}

.SchedulerTopVersionTimeline {
  border-bottom: 1px solid #1C2226;
  z-index: 10;
  background-color: $light_backgroundGray;
}

.SchedulerTopVersionTimeline__selector,
.SchedulerTopVersion__selector {
  @extend .FontStyle--Bold;
  cursor: pointer;

  .arrow-icon {
    display: inline-block;
    margin: 0 0 0 5px;
    top: 13px;
  }

  &:hover{
    transform: scale(1.02);
  }
}

.SchedulerTopVersionTimeline __arrow,
.SchedulerTopVersion__arrow {
  display: inline-block;
  margin: 2px 0 0 5px;
  transition: 0.1s all ease-out;

  .open & {
    transform: rotate(180deg);
  }
}

.SchedulerTopVersionTimeline__list,
.SchedulerTopVersion__list {
  display: flex;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background: $light_backgroundGray;
  overflow: visible;
  overflow-y: hidden;
  justify-content: space-around;
  align-items: flex-start;
  height: 0;
  z-index: 999;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;

  .darkmode & {
    background: #393939;
  }

  .SchedulerTopVersionTimeline__list__Content__Container,
  .SchedulerTopVersion__list__Content__Container {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1500px;
    overflow: none;
    padding: 0 180px;
    justify-content: space-around;
    transform: translate3d(0, -100vh, 0);
    transition: 0.2s transform ease-out;
  }
}

.DraftSelectionList__wrapper {
  text-decoration: none;
  font-size: 14px;
  padding: 8px 16px;
  border: 1px solid $light_panel_border;
  margin: 0 5px;
  color: $black;
  border-radius: 2px;
  height: 62px;
  line-height: 46px;

  &:hover {
    border: 1px solid $light_gray;
  }

  &.active {
    border: 1px solid $white;
    color: $white;
    cursor: default;
    background-color: $light_panel_background;
  }
}

.SchedulerTopVersionTimeline__save-indicator,
.SchedulerTopVersion__save-indicator {
  position: absolute;
  right: 10px;
  font-size: 14px;
  top: 15px;
}

.SchedulerTopVersionTimeline__activity-done-indicator,
.SchedulerTopVersion__activity-done-indicator {
  position: absolute;
  left: 15px;
  font-size: 14px;
  top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  transition: all 0.4s;
  width: 200px;

  &.active {
    opacity: 1;
  }
}

.activity-indicator {
  // position: absolute;
  // left: 15px;
  // font-size: 14px;
  // top: 13px;
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  // align-items: center;
  // opacity: 0;
  // transition: all 0.4s;
  // width: 200px;
  position: fixed;
  left: 0;
  font-size: 50px;
  top: 65px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  opacity: 0;
  transition: all 0.4s;
  right: 0;
  bottom: 0;
  z-index: 998;
  text-align: center;
  pointer-events: none;
  // display: none;
  background: rgba(239, 242, 243, 0.7);

  .darkmode & {
    background: rgba(30, 30, 30, 0.7);
  }

  &.active {
    opacity: 1;
    display: flex;
  }

  .Preloader--Container {
    background: rgba(239, 242, 243, 0.7);

    .darkmode & {
      background: rgba(30, 30, 30, 0.7);
      color: $white;
    }
    // background: transparent;
    // width: 30px;
    // height: 30px;
    // position: relative;
    // margin-right: 20px;
  }

  .Preloader {
    // width: 30px;
    // height: 30px;
  }
}
$loader--width: 300px;
$loader-dot--size: 22px;

.activityLoader {
  height: $loader-dot--size;
  width: $loader--width;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader--dot {
  animation: loader ease-in-out 3s infinite;
  height: $loader-dot--size;
  width: $loader-dot--size;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 3px solid;

  &:first-child {
    background-color: $color-conceptional;
    border-color: $color-conceptional-darkmode;
    animation-delay: 0.5s;
  }

  &:nth-child(2) {
    background-color: $color-engineering-and-design;
    border-color: $color-engineering-and-design-darkmode;
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    background-color: $color-plan-and-select;
    border-color: $color-plan-and-select-darkmode;
    animation-delay: 0.3s;
  }

  &:nth-child(4) {
    background-color: $color-execution;
    border-color: $color-execution-darkmode;
    animation-delay: 0.2s;
  }

  &:nth-child(5) {
    background-color: $color-occupied;
    border-color: $color-occupied-darkmode;
    animation-delay: 0.1s;
  }

  &:nth-child(6) {
    background-color: $color-completed;
    border-color: $color-completed-darkmode;
    animation-delay: 0s;
  }
}

.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  font-weight: bold;
  font-size: 22px;
  // &:after{
  //   animation: loading-text 3s infinite;
  //   content: "";
  // }
}
@keyframes loader {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX($loader--width - $loader-dot--size);
  }

  65% {
    transform: translateX($loader--width - $loader-dot--size);
  }

  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }
}


.SchedulerTopVersion__pending{
  position: absolute;
  left: 130px;
  font-size: 0.8rem;
  color: rgba(28, 34, 38, 0.55);
}
