@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // FIX: CENTER BOOKINGS IN ROW
  .Plotter {
    height: calc(100% - #{$datesHeight});
  }

  .Well--Booking,
  .Well-Booking__Sequence {
    top: 14px;

    .header {
      line-height: 40px;
    }
  }

  .Well-Booking__Sequence {
    top: 0;

    .rigmove {
      top: 14px;
    }
  }
  // FIX: CENTRATION OF FREE TIME
  // FIX CENTER BOOKINGS IN ROW
  .FreeTime {
    &.Calendar--Entry {
      top: 6px;
    }
  }
  // FIX: PLACE YEAR better
  .Dates .Year {
    transform: translateY(-20px);

    .Year--Title {
      transform: translateY(-20px);
    }
  }
  // FIX: STYLE TOOLABR FOR IE 11
  .ToolBar {
    .NewRequestButton {
      left: 5px;

      .contextmenu {
        top: -20px !important;
      }
    }

    .Requests__Button {
      &:before {
        transform: translate3d(-35%, -50%, 0);
      }
    }

    .Requests__Panel__Container {
      left: 0;
    }
  }
  // FIX: PANELS
  .Panel {
    box-shadow: #333333 0 0 10px;
    background-color: #1C2226;
    border-radius: 6px;

    .Panel__Footer {
      left: 0;
      height: 45px;
      overflow: hidden;

      .Panel__Button {
        position: absolute;
        width: 50%;
      }

      .Panel__Button--cancel {
        left: 0;
      }

      .Panel__Button--next,
      .Panel__Button--submit {
        left: 50%;
      }
    }
  }
  // FIX: Center Overview ie11
  .Overview {
    .Overview__Module {
      min-width: 250px;
    }
  }

  .ReactModalPortal {
    .ReactModal__Wrapper {
      height: 300px !important;

      .ReactModal--Form {
        height: 300px;
      }

      .ReactModal__Buttons {
        position: fixed;
        width: 450px;
        height: 45px;
      }
    }
  }

  .WellPopover__ContentText--rigmove {
    padding: 0 64px 0 4px;
  }

  .ToolBar {
    .NewRequestButton {
      left: 1px;
    }

    .Requests__Button {
      &:before {
        width: 32px;
        height: 32px;
        transform: translate3d(-3px, -3px, 0);
      }

      &:hover {
        &:before {
          width: 40px;
          height: 40px;
        }
      }
    }

    .TopNavigation__ListItem {
      min-height: 30px;
      margin-top: 15px;
      margin-bottom: 10px;
      top: 5px;
      height: 30px;
    }

    body {
      .ToolBarContainer {
        width: $ToolBarWidth_closed;

        &.open {
          width: $ToolBarWidth_open;
        }
      }
    }

    .Scheduler--Overview__Rig-List-Container {
      .Scheduler--Overview__Rig-List {
        height: calc(100% - #{$datesHeight});
      }
    }

    .SchedulerTopVersionTimeline__list__Content__Container,
    .SchedulerTopVersion__list__Content__Container {
      min-width: 70vw;
      max-width: 1500px;
    }
  }

  .MastersModule__listitem {
    flex: 1 1 0;
    min-width: 0;
  }

  .MastersModule__listitem-icon {
    // display: block;
    &:after {
      left: 0;
      top: 0;
    }
  }
  //
  .timeline {
    &__month-recap {
      left: 3%;
    }

    &__change-desc-icon {
      position: absolute;
      display: block;
      top: 60%;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        position: absolute;
        top: 50px;
      }
    }
  }
}