.Scheduler--Container {
  position: relative;
  display: flex;
  width: 100%;
  // margin-top: 60px;
  // height: calc(100% - 60px);
  height: 100%;
  // height: calc(100% - 60px);
  overflow: hidden;
}

.Scheduler {
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  width: calc(100% - #{$ToolBarWidth_closed});
  flex-direction: column;
  background-color: $light_backgroundGray;

  &.toolbarOpen {
    width: calc(100% - #{$ToolBarWidth_open});
  }
}