.Select__Dropdown {
  width: 55%;
  margin: 0 auto;
  outline: none;

  &.active {
    .Select__Dropdown__Elm__single-value {
      color: $black;
    }

    .Select__Dropdown__Elm__control {
      background-color: $white;
    }
  }

  &.Select__Dropdown__Elm--is-disabled {
    .Select__Dropdown__Elm__control {
      background-color: $gray;

      .Select__Dropdown__Elm__indicators{
        display: none;
      }
    }
  }

  &.Select__Dropdown--big {
    width: 80%;
  }

  &.white {
    background-color: rgba(245, 248, 249, 0.15);
  }

  .Select__Dropdown__Elm__control {
    background-color: $light_panel_background;
    color: $white;
    border-color: $light_gray;
    box-shadow: none;
    outline: none;

    &:hover {
      background-color: rgba(245, 248, 249, 0.15);
      border-color: $light_gray;
    }

    &.Select__Dropdown__Elm__control-is-focused {
      background-color: $white;
      color: $light_panel_background;
      border: 0;
      outline: none;
      box-shadow: none;

      .Select__Dropdown__Elm__single-value {
        color: $light_panel_background;
      }
    }
  }

  .Select__Dropdown__Elm__single-value {
    color: $white;
    text-transform: capitalize;
  }

  .PanelForm__InputPart {
    background-color: $light_panel_background;
  }

  .Select__Dropdown__Elm__menu {
    margin-top: 0;
    background-color: $light_panel_background;
    color: $white;
    border: 1px solid $white;
    border-top: none;

    .Select__Dropdown__Elm__option {
      text-transform: capitalize;

      &:hover {
        background-color: #e0e0e0 !important;
        color: $light_panel_background;
      }

      &.Select__Dropdown__Elm__option--is-focused,
      &.Select__Dropdown__Elm__option--is-selected {
        background-color: $white;
        color: $light_panel_background;
      }
    }
  }
}

.Select__Dropdown__Elm{
  color: $black;

}

.Select-menu {
  border: 1px solid $white !important;
}

.Select__Dropdown--small{
  .Select__Dropdown__Elm__menu{
    > div{
      max-height: 105px;
    }
  }
}

.White__Dropdown {
  &.invalid .Select__Dropdown__Elm__control {
    border-color: $red;
  }
  .Select__Dropdown__Elm__option {
    text-transform: capitalize;

    &:hover {
      background-color: #e0e0e0 !important;
      color: $light_panel_background;
    }

    &.Select__Dropdown__Elm__option--is-focused,
    &.Select__Dropdown__Elm__option--is-selected {
      background-color: $white;
      color: $light_panel_background;
    }
  }
}
