.Dropdown {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    margin-left: $paddingSmall;
  }

  * {
    outline: none;
  }

  .Select-control {
    cursor: pointer;
  }

  .Select-menu-outer {
    border: 1px solid white;
    padding: $paddingNormal;
    background-color: $light_backgroundGray;
  }

  .Select-option {
    cursor: pointer !important;
  }

  .Select.is-focused {
    border: 0;
    outline: none;

    .Select-control {
      background-color: transparent;
    }
  }

  .Select-value-label {
    @extend .FontStyle--Bold;
  }

  .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: 0 !important;
  }
}
