.contextmenu {
  display: none;
  position: absolute;
  min-width: 200px;
  left: 100%;
  transform-origin: 50% 100%;
  transform: translate3d(12px, -5px, 0);
  -ms-transform: translate3d(12px, 25px, 0);
  z-index: 910;

  &.contextmenu--item-wrapper-active {
    display: block;
  }

  .arrow {
    position: absolute;
    left: -19px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid $black;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    clear: both;
  }
}

.contextmenu--item-wrapper {
  background: $black;
  padding: 8px 10px;
  color: $white;
  display: block;
  cursor: pointer;
  border-top: 1px solid $white;

  > span {
    font-weight: normal;
  }

  &:hover {
    background: $white;
    color: $light_panel_background;

    &:first-of-type {
      &::before {
        border-right: 10px solid $light_gray;
      }
    }
  }

  &:first-of-type {
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    border-top: none;
  }

  &:last-of-type {
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }
}