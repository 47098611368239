.darkmode {
  .Rig--draft-rig {
    color: $black;

    .Rig__Wrapper {
      background-color: #ededed;
    }
  }
}

.Rig {
  width: 100%;
  border-bottom: 2px solid $white;

  .darkmode & {
    border-bottom: 2px solid #8D9198;
  }
}

.Rig__Wrapper {
  background-color: $white;
  width: auto;
  border-radius: $borderRadius;
  text-align: left;
  margin: 0;
  position: relative;
  top: -1px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  border: 1px solid $white;
  cursor: pointer;
  transition: border 100ms $easeInOut;

  &:hover {
    border: 1px solid $black;
  }

  .darkmode & {
    background: #8D9198;
    border-color: #8D9198;
  }

  .Rig--selected & {
    border: 1px solid $black;

    &::after {
      left: 95%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(0, 0, 0, 0);
      border-right-color: #000000;
      border-width: 10px;
      margin-top: -10px;
      z-index: 999;
    }
  }

  .Rig--draft-rig & {
    background: url("~../../assets/images/png/fake_rig.png");
    border: none;

    .darkmode & {
      background: url("~../../assets/images/png/fake_rig_dark.png");
    }

  }

  .Rig--partner-rig & {
    background: #B2C2CC;

    .Rig__Partner{
      font-size: 0.8rem;
    }

    .darkmode & {
      background: url("~../../assets/images/png/fake_rig_dark.png");
    }

  }
}

.Rig__Icon {
  flex-basis: 17px;
  padding-left: 5px;

  img{
    display: block;
  }
}

.Rig__Name {
  font-size: 14px;
  line-height: 15px;
  flex: 1;
  padding-left: 5px;
}

.Rig__Pressure {
  font-size: 10px;
  text-align: right;
  flex-basis: 25px;
  padding-right: 3px;
  display: block;
}
.Rig__Visibility {
  font-size: 10px;
  text-align: right;
  padding-right: 3px;
  padding-left: 3px;
  display: block;
}
$popoverPadding: 10px;

.popover {
  display: none;
  overflow: auto;
  position: absolute;
  z-index: 995;
  width: 270px;
  left: calc(100%);
  top: $datesHeight + 9;
  background: $light_component_background;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.20);
  border-radius: $borderRadius;
  max-height: 85%;

  &.Rig__Popover--active {
    display: block;
  }
}

.popover__heading {
  margin: 0;
  @extend .FontStyle--Bold;
  padding: 10px $popoverPadding 0;
  font-size: 24px;
  line-height: 35px;
}

.popover__subheading {
  padding: 0 $popoverPadding;
  font-size: 16px;
  line-height: 16px;
}

.popover__rigtype {
  padding: 0 $popoverPadding;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0;
}

.popover__rigtype-icon {
  flex-basis: 22px;
  height: 20px;

  img {
    width: 20px;
    height: 22px;
  }
}

.popover__rigtype-content {
  // flex: 1;
  padding-left: 5px;
  font-size: 16px;
}

.popover__rigtype-separator {
  height: 1px;
  background: $black;
  flex: 1;
  margin: 0 5px;
}

.popover__rigtype-pressure {
  flex-basis: 25px;
  font-size: 16px;
}

.popover__enddate {
  padding: 7px 10px;
  font-size: 12px;
  line-height: 15px;
}

.popover__specs {
  padding: 0 $popoverPadding;
  margin: 10px 0 0;
}

.popover__partheading {
  @extend .FontStyle--Bold;
  font-size: 16px;
  line-height: 20px;
}

.popover__specs-list {
  margin: 0;
  padding: 0;
  list-style-type: circle;
}

.popover__specs-listitem {
  margin: 0 0 0 20px;
  line-height: 20px;

  span {
    position: relative;
    left: -5px;
  }
}

.popover__summary {
  padding: 0 $popoverPadding;
  margin: 20px 0 0;
}

.popover__summary-part {
  line-height: 20px;

  span {
    @extend .FontStyle--Bold;
  }
}

.popover__actions {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 0;
  margin: 20px 0;
  border-top: 1px solid $black;
  flex-direction: column;
}
.popover__sortingtitle {
  text-transform: uppercase;
  font-size: 12px;
  flex: 0;
  margin: 0;
  padding: 0;
  display: block;
  line-height: 16px;
}
.popover__sortingbtns {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1px 0;
  align-items: center;
}
.popover__sortingup {
  width: 20px;
  height: 10px;
  line-height: 0;
  display: block;
  transform: rotate(-180deg);
  margin-left: 6px;
  margin-bottom: 16px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  span {
    display: block;
  }
}
.popover__sortingdown {
  width: 20px;
  height: 10px;
  line-height: 0;

  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  span {
    display: block;
  }
}

.popover__buffertime {
  margin: 20px 0 0;
}

.popover__buffertime-button {
  display: block;
  text-align: center;
  border: 0;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  padding: 15px 0;
  background: transparent;
  width: 100%;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    background-color: $gray;
    font-weight: bold;
  }
}

.popover__buttons {
  display: flex;
  margin: 15px 0 0;
  border-top: 1px solid $black;
}

.popover__button {
  text-align: center;
  flex: 1;
  background: $light_component_background;
  border: 0;
  padding: 15px 0;
  font-size: 13px;
  cursor: pointer;

  &:first-of-type {
    border-right: 1px solid $black;

    &:hover {
      background-color: $gray;
      font-weight: bold;
    }
  }

  &.popover__button--primary {
    background: $black;
    color: $white;

    &:hover {
      color: $black;
      background-color: $gray;
      font-weight: bold;
    }
  }
}
