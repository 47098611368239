.ToolBar {
  position: relative;
  display: flex;
  background-color: $light_component_background;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.darkmode{
    background: #0F0F0F;
    color: $white;
  }

  .NewRequestButton {
    position: relative;
  }

  .Requests__Button {
    position: relative;
    width: 25px;
    height: 25px;
    color: $white;
    top: 45px;
    // background: black;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    cursor: pointer;

    .Requests__Button--Number {
      position: absolute;
      width: 100%;
      top: 25%;
      left: 1px;
      line-height: 100%;
      text-align: center;
      vertical-align: middle;
      z-index: 1;
      transform: translate3d(0, 1px, 0);
    }

    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      transform: translate3d(-7px, -7px, 0) scale(0.8);
      background-color: #FC5644;
      border-radius: 50%;
      transition: all 150ms $easeInOut;
    }

    &.empty {
      color: $black;
      font-weight: bold;

      &:before {
        background-color: transparent;
        border: 2px solid $black;
        border-radius: 50%;
      }
    }

    &:hover {
      &:before {
        transform: translate3d(-7px, -7px, 0) scale(1);
      }
    }
  }

  .Requests__Panel__Container {
    position: absolute;
    width: 100%;
    height: 100%;
    // height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 150ms $easeInOut;

    &.show {
      opacity: 1;
    }

    .Requests__Panel__Container__Menu {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 10px;
      // margin-bottom: 14px;
    }

    .Requests__Panel {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}
@import 'ToolBarHeader';
@import 'ToolBarContent';
@import 'ToolBarFooter';
