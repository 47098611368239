.InBetweenSpace {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #923487 transparent transparent transparent;
  transform: translate3d(0, -20px, 0);
  top: 10px;
  transition: transform 250ms ease;
  z-index: 1000;
  pointer-events: none;

  &.active {
    border-width: 20px 6px 0 6px;
  }
}