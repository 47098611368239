.NowTo1YearIndicator {
  border: 1px solid #B2C2CC;
  height: 100vh;
  height: -webkit-calc(100% - 0px);
  position: absolute;
  text-align: center;
  top: 0;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  text-transform: uppercase;
  pointer-events: none;
  z-index: 3;

  span {
    font-size: 11px;
    display: block;
    background: $light_component_background;
    width: 50px;
    margin: -9px auto 0;

    .darkmode & {
      background: $color-darkmode;
      color: $white;
    }
  }
}