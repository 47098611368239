$HeaderPadding: 10px;

.HeaderContainer {
  position: relative;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  width: 100vw;
  height: 60px;
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 999;
  background: $white;

  &.darkmode {
    background: #0f0f0f;
    color: $white;
  }
}

.PageHeader__Wrapper {
  display: flex;
  height: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}

.PageHeader__LogoWrapper {
  align-items: center;
  display: flex;
  flex: 1;
  padding-left: $HeaderPadding;
}

.PageHeader__LogoImage {
  height: 40px;
}

.PageHeader__Navigation {
}

.PageHeader__UserProfile {
  .RoundedDropDown {
    flex: 0 0 130px;
    margin-right: 15px;
  }
  .RoundedDropDown--Elements--Container {
    width: 130px;
  }
}

.TopNavigation {
  flex: 3;
}

.TopNavigation__Wrapper {
  height: 100%;
}

.TopNavigation__List {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.TopNavigation__ListItem {
  align-items: center;
  display: flex;
  margin: 0 20px;
  height: 100%;
  min-height: 56px;
  padding-top: 3px;
  border-bottom: 3px solid $white;
  position: relative;
  @include fastTransitionInOut;

  .darkmode & {
    border-bottom: 3px solid $black;
  }

  &:hover {
    border-bottom: 3px solid $black;
  }

  &.active {
    border-bottom: 3px solid $black;

    .darkmode & {
      border-bottom: 3px solid #d4d6da;
    }
  }

  .TopNavigation__beta-indicator {
    position: absolute;
    left: 70%;
    top: 12px;
  }
}

.TopNavigation__Link {
  color: $black;
  text-decoration: none;
  font-size: 18px;

  .darkmode & {
    color: #d4d6da;
  }

  .active & {
    font-weight: bold;
  }
}

.UserProfile {
  // width: 150px;
  flex: 1;
  text-align: right;
  padding-right: $HeaderPadding;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UserProfile__NameWrapper {
  flex: 0;
  min-width: 100px;
}
.UserProfile__logout {
  color: $black;
  font-size: 0.7rem;
  display: block;
  cursor: pointer;
  padding-top: 4px;

  .darkmode & {
    color: #d4d6da;
  }
}

.UserProfile__Name {
  color: $black;
  font-size: 14px;
  text-align: right;
  line-height: 10px;
  display: inline-block;
  padding: 7px;

  .darkmode & {
    color: #d4d6da;
  }
}

.UserProfile__ImageWrapper {
}

.UserProfile__Image {
  height: 40px;
  width: 40px;
  border-radius: 800px;
  display: block;
}
