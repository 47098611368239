$padding: 20px;

.InputMissingModule {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.InputMissingModule--wrapper {
  border: 1px solid $light_gray;
  background: $white;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  height: 60vh;
  overflow-y: hidden;
}

.InputMissingModule__header {
  color: $red;
  padding: 8px $padding;
  border-bottom: 1px solid $light_gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.InputMissingModule__notifyIcon__Container {
  display: flex;
  align-items: center;
}

.InputMissingModule__notifyIcon {
  border: 1px solid $red;
  width: 15px;
  height: 15px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  display: inline-block;
  line-height: 14px;
  margin-right: 4px;
  position: relative;
}

.InputMissingModule__toggleIcon {
  margin: 9px 0 0;
  color: $black;
  float: right;
}

.InputMissingModule--list {
  overflow-y: scroll;
}

.InputMissingModule--listitem {
  padding: 0 0 $padding;
  margin: $padding;
  border-bottom: 1px solid $red;
  font-size: 15px;
  line-height: 18px;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.InputMissingModule--listitem-header {
  @extend .FontStyle--Bold;
}

.InputMissingModule--listitem-button {
  border: 1px solid $light_gray;
  border-radius: $borderRadius;
  text-align: center;
  padding: 8px 25px;
  display: block;
  margin: 8px 0 0;
}