.well-list {
  background-color: $black;
  opacity: 0.95;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  justify-content: center;

  .well-list--wrapper {
    position: relative;
    width: 100%;
    // max-width: 460px;
    height: 100%;
    background-color: $black;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    padding: $paddingBig;

    .well-info-wrapper,
    .well-list-scroll-wrapper {
      padding: $paddingBig;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .well-list-scroll-wrapper {
      flex: 2;
      // padding-right: $paddingNormal;
    }

    .well-info-wrapper {
      flex: 3;

      .info-pair {
        display: flex;
        flex-direction: row;
        padding: $paddingSmall 0;

        .left {
          flex: 1;
        }

        .right {
          flex: 2;
        }

        button {
          bottom: 0;
        }
      }
    }
  }
}