.NextScheduleModule {
  max-height: 95%;
}

.NextScheduleModule__list {
  overflow: visible;
  max-height: 100%;
}

.NextScheduleModule__listitem {
  position: relative;
  border: 1px dashed $black;
  border-radius: $borderRadius;
  margin: 0 0 10px;
  text-decoration: none;
  color: $black;
  display: block;

  &.NextScheduleModule__listitem--new {
    -webkit-animation: borderBlink 0.3s step-end infinite;
    animation: borderBlink 0.3s step-end infinite;
  }

  &:hover {
    border: 1px dashed $light_gray;
  }
}

.NextScheduleModule__listitem-subheader {
  background: $light_component_background;
  text-align: center;
  padding: 12px 0;

  .darkmode & {
    color: $black;
  }
}

.NextScheduleModule__listitem-header {
  padding: 5px 10px 0;
  @extend .FontStyle--Bold;
  display: block;
}

.NextScheduleModule__listitem-date {
  padding: 0 10px 5px;
  display: block;
}

.NextScheduleModule__listitem-contexttrigger {
  float: right;
  font-size: 33px;
  font-weight: bold;
  width: 20px;
  display: block;
  margin: 5px;
  color: $light_gray;
  cursor: pointer;

  &.active,
  &:hover {
    color: $black;
  }
}
@-webkit-keyframes borderBlink {
  from,
  to {
    border-color: $light_gray;
  }

  50% {
    border-color: $black;
  }
}
@keyframes borderBlink {
  from,
  to {
    border-color: $light_gray;
  }

  50% {
    border-color: $black;
  }
}
