.Request {
  display: flex;
  flex-direction: column;
  position: static;
  margin-bottom: 1px;
  padding-left: 3px;
  line-height: 16px;
  margin-left: 10px;
  cursor: pointer;
  color: $light_panel_background;
  @include fastTransitionInOut;

  &:hover {
    box-shadow: 0 0 0 1px $black inset;
  }

  &.Request--existing {
    margin-bottom: 35px !important;
  }

  // &::before {
  //   content: " ";
  //   background: $red;
  //   height: 68px;
  //   width: 1px;
  //   position: absolute;
  //   left: 10px;
  // }

  &.Processed::before {
    display: none;
  }

  &:last-of-type {
    &::before {
      display: none;
    }
  }

  &.Preview {
    cursor: default;
    margin: 0 auto;
    width: 65%;

    &:hover {
      box-shadow: none;
    }

    &::before {
      display: none;
    }
  }

  .Request--Status {
    background: $red;
    color: $white;
    padding: 3px 0;
    position: relative;
    left: -13px;
    width: 65px;
    height: 14px;
    font-size: 9px;
    text-align: center;
    line-height: 7px;
    top: 5px;

    &::before {
      content: " ";
      width: 1px;
      height: 10px;
      position: absolute;
      left: 0;
      top: -10px;
      display: block;
      background: $red;
    }
  }

  &.Processed .Request--Status::before {
    display: none;
  }

  .Request--Name--Container {
    flex: 2;
    // margin-top: 13px;
    .Request--Name {
      font-size: 14px;
    }

    .Request--op {
      font-size: 10px;
    }
  }

  .Request--Container {
    display: flex;
    padding: 10px 7px;
    flex-direction: row;
  }

  .Request--Time-and-Icons--Container {
    display: flex;
    flex: 1;
    align-items: center;
    // margin-top: 13px;
    .Request--Icons--Container {
      display: flex;
      flex: 15;
      justify-content: flex-end;
      margin-right: 2px;
    }

    .Request--Time--Container {
      display: flex;
      flex: 10;
      flex-direction: column;
      justify-content: space-around;

      .Request--Time {
        font-size: 20px;
      }

      .Request--Days {
        font-size: 8px;
      }
    }
  }
}
