.Plotter {
  position: relative;
  // height: calc(100% - #{$datesHeight} + 16px);
  height: calc(100% - #{$datesHeight});
  // crome hack
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    height: calc(100% - #{$datesHeight} + 15px);
  }
  width: auto;
  padding-top: $paddingSmall;
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: overlay;
  z-index: 4;

  &::-webkit-scrollbar {
    display: none;
  }

  .Plotter--Row {
    position: relative;
    color: black;
    display: flex;
    align-items: center;
    border-bottom: 2px solid $white;
    transform: height 250ms ease;

    .darkmode & {
      border-bottom: 2px solid #8d9198;
    }

    .Date_Marker {
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: indigo;
      pointer-events: none;
      z-index: 1000;
    }

    &.isOver {
      outline: 4px solid indigo;
    }

    &.isInBetween {
      outline: 4px solid orange;
    }

    &.removeDragOver {
      // pointer-events: none;
      // background-image: url("~../../assets/images/png/shade_out_row_stroke.png");
      // background-size: 25px 50px;
      // background-repeat: repeat-x;
      // opacity: 0.5;

      // > div {
      //   opacity: 0.1 !important;
      // }

      &:after {
        content: attr(data-err);
        position: absolute;
        color: $white;
        height: 20px;
        width: auto;
        padding: 5px 15px;
        background-color: #1c2226;
        text-align: center;
        vertical-align: middle;
        line-height: 20px;
        z-index: 10;
        border-radius: 3px;
      }
    }

    &.active {
      height: 80px;

      .dragging {
        pointer-events: none;
        background-color: $debugColor01;
      }

      > * {
        pointer-events: none;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }
}
