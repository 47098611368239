.StrokeButton--button {
  display: flex;
  width: 123px;
  height: 35px;
  background-color: transparent;
  border: 1px solid $light_panel_border;
  border-radius: $borderRadius;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include fastTransitionInOut;

  &.white {
    border: 1px solid $light_gray;

    &:hover {
      border: 2px solid white;
    }
  }

  &.wide {
    width: 163px;
  }

  &:hover {
    border: 2px solid $light_panel_border;
  }

  &.readonly {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.addwell-btn {
  position: absolute;
  display: flex;
  width: 160px;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  border: 1px solid #8D9198;
  border-radius: 4px;
  text-align: center;
  font-size: 0.8rem;
  color: #1C2226;
  cursor: pointer;
  pointer-events: all;
  // position: absolute;
  // top: calc(20vh + 10%);
  top: 10px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  pointer-events: all;
  transition: opacity 300ms ease 200ms;
  z-index: 999;

  .expanded & {
    opacity: 0;
  }

  &:hover {}
}

.addwell-btns {
  // position: fixed;
  // top: 50%;
  position: absolute;
  top: 210px;
  left: 50%;
  transform: translate3d(-50%, -200px, 0);
  display: flex;
  flex-direction: column;
  width: 160px;
  pointer-events: all;
  transition: opacity 300ms ease 200ms;
  z-index: 999;

  .expanded & {
    opacity: 0;
  }

  .btn {
    border: 1px solid #8D9198;
    background: $white;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    color: $black;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;

    &:first-of-type {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      color: $white;
      background: $black;
    }

    &:hover {
      font-weight: bold;
    }
  }

  .cancel {
    font-size: 10px;
    color: #8D9198;
    text-transform: uppercase;
    text-align: center;
    padding-top: 5px;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
}