.Round__Close__button {
  display: flex;
  background-color: transparent;
  color: $black;
  align-items: center;
  justify-content: center;
  border-radius: $borderRadius;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;

  svg {
    @include fastTransitionInOut;
  }

  &:hover {
    svg {
      transform: translate3d(-3px, 0, 0);
    }
  }
}