@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  body,
  html {
    height: 100%;
    width: 100%;
    overflow: auto;
    position: fixed;
  }

  .Plotter {
    height: calc(100vh - 390px);
  }

  .Scheduler--Overview__Rig-List-Container {
    height: calc(100vh - 300px);
  }

  .Scheduler--Overview__Rig-Plotter-Container {
    -webkit-overflow-scrolling: touch;
  }

  .Well--Booking,
  .Well-Booking__Sequence {
    top: 14px;

    .header {
      line-height: 40px;
    }
  }

  .Well-Booking__Sequence {
    top: 0;
  }

  .FreeTime {
    &.Calendar--Entry {
      top: 6px;
    }
  }
  // FIX: PANELS
  .Panel {
    .Panel__Footer {
      left: 0;
      height: 45px;
      overflow: hidden;

      .Panel__Button {
        position: absolute;
        width: 50%;
      }

      .Panel__Button--cancel {
        left: 0;
      }

      .Panel__Button--next,
      .Panel__Button--submit {
        left: 50%;
      }
    }
  }

  .contextmenu {
    .arrow {
      transform: translate3d(0, -4px, 0);
    }
  }
}