.CalendarLegend {
  // position: fixed;
  // bottom: 20px;
  // right: 20px;
  // z-index: 999;
  position: absolute;
  bottom: 20px;
  z-index: 998;
  right: 20px;
}

.CalendarLegend__Wrapper {
  display: flex;
}

.CalendarLegend_Elm {
  &:hover {
    font-weight: bold;

    .CalendarLegend__Button {
      font-weight: bold;

    }
  }
}

.CalendarLegend__Share {}

.CalendarLegend__ButtonGroup {
  box-shadow: 0 3px 8px 0 rgba(16,54,67,0.10);
  background: none;
  margin-left: 20px;
}

.CalendarLegend__Roleswitch {
  margin-right: 20px;
  box-shadow: 0 3px 8px 0 rgba(16,54,67,0.10);

  .CalendarLegend__Button {
    border-radius: 5px !important;
  }
}

.CalendarLegend__Button {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 15px;
  border: 0;
  height: 40px;
  border-right: 1px solid #B2C2CC;
  cursor: pointer;
  position: relative;

  &:not(:last-of-type):not(:first-of-type) {
    border-radius: 0;
  }

  &:last-of-type {
    border-right: 0;
  }

  &:first-of-type:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-of-type:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.CalendarLegend__Button--disabled{
  background: #EFF2F3;
  cursor: default;
  opacity: 0.7;
  position: relative;

  span{
    background: #FFFFFF;
    position: absolute;
    top: -70px;
    padding: 15px 25px;
    width: 190px;
    opacity: 1 !important;
    left: -20px;
    display: none;
    font-size: 13px;
  }

  &:hover{
    font-weight: normal;

    span{
      display: block;
    }
  }


}

.CalendarLegend__NumIndicator {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -8px;
  top: -8px;
  background: #FF0000;
  color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 3px 8px 0 rgba(16,54,67,0.10);
}

.CalendarLegend__ExplanationsButton {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 0;
  font-size: 15px;
  border: 0;
  height: 40px;
  box-shadow: 0 3px 8px 0 rgba(16,54,67,0.10);
  margin-left: 20px;
  width: 40px;
  border: 1px solid #ffffff;
  cursor: pointer;

  &.active {
    border: 1px solid $black;
  }
}

.CalendarLegend__OverridePValuesPanel {
  display: none;
  position: fixed;
  bottom: 65px;
  right: 480px;
  background: white;
  border: 1px solid $black;
  box-shadow: 0 3px 8px 0 rgba(16,54,67,0.10);
  border-radius: 5px;
  padding: 30px;
  width: 600px;

  .content {
    height: calc(70vh);
    width: 100%;
    display: flex;
    flex: 1;
  }

  &.active {
    display: flex;
  }

}

.ExplanationsPanel {
  display: none;
  position: fixed;
  bottom: 65px;
  right: 20px;
  background: white;
  border: 1px solid $black;
  box-shadow: 0 3px 8px 0 rgba(16,54,67,0.10);
  border-radius: 5px;
  padding: 5px;

  &.active {
    display: flex;
  }
}

.ExplanationsPanel__Part {
  margin: 10px 0;
  padding: 0 10px;
  border-right: 1px solid $black;
  min-width: 180px;

  &:last-of-type {
    border-right: 0;
  }
}

.ExplanationsPanel__Heading {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.ExplanationsPanel__ListItem {
  display: flex;
  align-items: center;
  line-height: 25px;
}


.ExplanationsPanel__ListItemValue {
}
.ExplanationsPanel__ListItemValue.link {
  color: $black;
}
.ExplanationsPanel__ListItemValue.rig {
  text-transform: capitalize;
}

.RolesPanel {
  display: none;
  position: fixed;
  bottom: 65px;
  right: 315px;
  width: 115px;
  background: white;
  border: 1px solid $black;
  box-shadow: 0 3px 8px 0 rgba(16,54,67,0.10);
  border-radius: 5px;
  padding: 5px;

  &.RolesPanel--master {
    right: 185px;
  }

  &.active {
    display: flex;
  }
}

.RolesPanel__Part {
  margin: 10px 0;
  padding: 0 10px;
  border-right: 1px solid $black;

  &:last-of-type {
    border-right: 0;
  }
}

.RolesPanel__ListItem {
  display: flex;
  align-items: center;
  line-height: 25px;

  a {
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
}

.WellStatus__Color {
  width: 30px;
  height: 15px;
  margin-right: 6px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.08);
  border-radius: 2px;
}

.Icons__Icon {
  margin-right: 10px;
  width: 15px;

  img {
    display: block;
  }

  &.Icons__Icon--large {
    width: 27px;
  }
  &.Icons__Icon--no-contract {
    width: 27px;
    background: url("~../../assets/images/png/no_contract.png");
    display: block;
    height: 27px;
  }
  &.Icons__Icon--contract-option {
    width: 27px;
    background: url("~../../assets/images/png/contract_option.png");
    display: block;
    height: 27px;
  }
}
