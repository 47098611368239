.RoundedDropDown {
  position: relative;
  display: block;
  width: 180px;
  height: 33px;
  margin-right: 10px;
  border-radius: $borderRadius;
  border: 1px solid $light_gray;
  overflow: hidden;
  font-size: 14px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;

    .RoundedDropDown__label {
      font-weight: bold;
      white-space: pre;
    }
  }

  .RoundedDropDown__circle {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 8px 0 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .RoundedDropDown__label {
    cursor: pointer;
    padding: 0 10px;
    line-height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {}
  }

  &.open {
    overflow: visible;
    background: $white;

    .RoundedDropDown--Elements--Container {
      display: block;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .RoundedDropDown__label {
      svg {
        transform: rotate(-180deg);
      }
    }

    &.up {}

    &.down {
      // transform: translate3d(0, 170px, 0);
      height: 270px;
      // .RoundedDropDown--Elements--Container {
      //   background-color: $black;
      // }
    }
  }

  &.hasValue {
    background: $white;

    .RoundedDropDown__label {
      font-weight: bold;
    }
  }

  .RoundedDropDown--Elements--Container {
    margin-top: 12px;
    position: absolute;
    left: -1px;
    // width: 180px;
    width: calc(100% + 2px);
    top: 15px;
    background: $white;
    padding: 10px;
    border: 1px solid $light_gray;
    border-radius: 10px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 999;
    display: none;
    max-height: 300px;
    overflow-y: scroll;
  }

  .arrow-icon {
    margin: 6px 0 0;
    height: 10px;
  }
}

.RoundedDropDown__Option {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }

  svg {
    margin-right: 6px;
  }
}

.RoundedDropDown__Option--status {
  &.RoundedDropDown__Option--selected {
    @extend .FontStyle--Bold;
  }
}

.RoundedDropDown__Option--status-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  flex-basis: 15px;
  margin-right: 6px;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.08);
  flex: 0 0 15px;
}

.RoundedDropDown__Option--separator {
  background: #B2C2CC;
  opacity: 0.3;
  height: 1px;
  margin: 10px 0;
  cursor: default;
}

.RoundedDropDown__Option--headline {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  margin: 5px 0;
  cursor: default;

  &:hover {
    font-weight: normal;
  }
}

.RoundedDropDown__Option-Value {
  padding: 5px 0;
}

.RoundedDropDown {
  .close {
    position: relative;
    position: absolute;
    right: 37px;
    display: inline-block;
    color: #fff;
    font-size: 20px;
    text-align: center;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    border: 1px solid $black;
    transition: all 0.15s;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    // top: 6px;
    transform: translate3d(0, 5px, 0) rotate(0deg);
    transition: opacity 350ms ease;

    > span {
      transform: translate3d(-1px, -1px, 0);
    }
  }

  .close.visible {
    opacity: 1;
    pointer-events: all;

    > span {
      &:before {
        transform: rotate(45deg);
        // animation: show 0.15s normal forwards ease-in-out, rotateBeforeIn 0.15s normal forwards ease-in-out;
      }

      &::after {
        transform: rotate(-45deg);
        // animation: show 0.15s normal forwards ease-in-out, rotateAfterIn 0.15s normal forwards ease-in-out;
      }
    }
  }

  .close {
    // transform: translate3d(0, 3px, 0);
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      position: absolute;
      width: 11px;
      height: 11px;
      transform: translate3d(0, 0, 0);

      &:after,
      &:before {
        position: absolute;
        content: ' ';
        height: 10px;
        transform: rotate(0deg);
        width: 1px;
        background-color: $black;
        transition: all 0.15s;
        border-radius: 2px;
      }
    }
  }
}
