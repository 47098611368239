/*

  HELPER FUNCTIONS

*/
@function multiply-px($value1, $value2) {
  @return calc(#{$value1} * #{$value2});
}
@function divide-px($value1, $value2) {
  @return calc(#{$value1} / #{$value2});
}
@function colorWithOpacity($color, $opacity: 0.3) {
  @return rgba($color, $opacity);
}
/*

  VARIABLES

*/
// WIDTH AND HEIGHT
$ToolBarWidth_closed: 60px;
$ToolBarWidth_closed_half: divide-px(#{$ToolBarWidth_closed}, 2);
$ToolBarWidth_open: 230px;
$ToolbarStart: -$ToolBarWidth_open + $ToolBarWidth_closed;
$rigListContainer--Width: 145px;
$datesHeight: 75px; // rigs
$rigRowHeight: 50px;
$rigRowHeightDouble: multiply-px(#{$rigRowHeight}, 2); // STYLING
$borderRadius: 6px; // ANIMATION
$regularTransition: 250ms;
$fastTransition: calc(#{$regularTransition} / 2);
$slowTransition: calc(#{$regularTransition} * 2);
$easeInOut: cubic-bezier(0.425, 0.420, 0.000, 0.755);
@mixin fastTransitionInOut {
  transition: all $fastTransition $easeInOut;
}
// PADDING
$paddingNormal: 10px;
$paddingBig: multiply-px(#{$paddingNormal}, 2);
$paddingXBig: multiply-px(#{$paddingNormal}, 4);
$paddingSmall: divide-px(#{$paddingNormal}, 2);
$paddingXSmall: divide-px(#{$paddingSmall}, 2); // BORDER
$topIndex: 10;
$overlayIndex: 8;
$toolbarIndex: 6; // COLORS
$black: rgba(28, 34, 38, 1);
$gray: #B7B7B7;
$light_backgroundGray: #EFF2F3;
$light_stroke: #D8D8D8;
$light_gray: #B2C2CC;
$light_standardHeaderColor: indigo;
$light_borderBlack: $black;
$light_preloader_background: $light_backgroundGray;
$light_preloader_dott: $black;
$light_component_background: #F5F8F9;
$light_panel_background: #1C2226;
$light_panel_border: $light_panel_background;
$white: #fff;
$red: #FC5644;
$validation-error-red: #E78680;
$dark_backgroundColor: rgba(50, 55, 50, 1);
$dark_standardHeaderColor: rgba(150, 155, 150, 1); // SHADOWS
$rightBlurShadow: 2px 2px 15px 0 rgba(0,0,0,0.4);
$bookingShadow: 1px 5px 8px -1px rgba(37,20,66,0.1); // DEBUG:
// 0 5px 8px 0 rgba(37,20,66,0.08);
$color-execution: #D1FFE6;
$color-execution-hover: #effff6; //rgba(239, 255, 246, 1);
$color-execution-darkmode: #6DBDA6;
$color-execution-darkmode-hover: #9cebd4; //rgba(156,235,212, 1);
$color-execution-border: #33E283;
$color-plan-and-select: #FFDBF5;
$color-plan-and-select-hover: #fff3fc; //rgba(255, 243, 252, 1);
$color-plan-and-select-darkmode: #D957AB;
$color-plan-and-select-darkmode-hover: #e37bbe; //rgba(227,123,190, 1);
$color-plan-and-select-border: #FF47C9;
$color-engineering-and-design: #FFECBC;
$color-engineering-and-design-hover: #fff9ea; //rgba(255, 249, 234, 1);
$color-engineering-and-design-darkmode: #E9A14D;
$color-engineering-and-design-darkmode-hover: #eeee88; //rgba(238,238,136, 1);
$color-engineering-and-design-border: #FFB600;
$color-plan-for-execution: #FDFDC4; // $color-possible: lightblue;
$color-plan-for-execution-hover: #ffffef; //rgba(255, 255, 239, 1);
$color-plan-for-execution-darkmode: #F1D960; // $color-possible: lightblue;
$color-plan-for-execution-darkmode-hover: #ecdd92; //rgba(236,221,146, 1);
$color-plan-for-execution-border: #E6E600;
$color-conceptional: #E6D8FE;
$color-conceptional-hover: #f5ebff; //rgba(245, 235, 255, 1);
$color-conceptional-darkmode: #A482E9;
$color-conceptional-darkmode-hover: #c5b0f0; //rgba(197,176,240, 1); //#A482E9;
$color-conceptional-border: #8E6FC3; //#A482E9;
$color-completed: #EAEAEA;
$color-completed-hover: #fcfcfc; //rgba(252, 252, 252, 1);
$color-completed-darkmode: #919495;
$color-completed-darkmode-hover: #b8c4c8; //rgba(184,196,200, 1);
$color-completed-border: #D4D7C6;
$color-occupied: #DAF0FF; // $color-darkmode: #393939;
$color-occupied-hover: #f0f9ff; //rgba(240, 249, 255, 1); // $color-darkmode: #393939;
$color-occupied-darkmode: #A2D9FF; // $color-darkmode: #393939;
$color-occupied-darkmode-hover: #c1e1f7; //rgba(193,225,247, 1);
$color-occupied-border: #6995B3;
$color-darkmode: rgba(57, 57, 57, 1); // DEBUG
$debugColor01: rgba(125, 200, 250, 0.3);
$debugColor02: rgba(200, 125, 250, 0.3);
$debugColor03: rgba(250, 200, 125, 0.3);
$debugColor04: rgba(200, 250, 125, 0.3);