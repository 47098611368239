.choose-month {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: center;
  background-color: #F5F8F9;
  border-bottom: 1px solid $light_panel_background;
  z-index: 10;

  &.open {
    background-color: orange;
  }

  &__month-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    z-index: 10;

    &--month {
      margin-right: 5px;
    }

    &--chevron {
      margin-left: 5px;
    }
  }

  &__List {
    position: absolute;
    display: flex;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #F5F8F9;
    top: 60px;
    left: 0;
    flex-direction: row;
    padding: 0 10vw;

    &--part {
      flex: 1;
      padding: 60px 20px 20px;
    }

    &--title {
      position: relative;
      font-size: 30px;
      margin-bottom: 20px;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        top: calc(100% + 10px);
        left: 0;
        background-color: #1C2226;
      }
    }

    &--element {
      cursor: pointer;
      font-size: 20px;
      height: 40px;
      border-bottom: 2px solid #B2C2CC;
      display: flex;
      align-items: center;

      &.selected {
        font-weight: bold;
      }

      &:hover {
        font-weight: bold;
      }
    }
  }
}

.timeline {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100vw;
  max-width: 2000px;
  height: 100%;
  z-index: 1;

  &__left-container {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: transform 350ms ease;
    flex-direction: column;

    > div {
      transform: translate3d(10px, 0, 0);
    }

    .timeline--open & {
      transform: translate3d(0, -40vh, 0);
    }
  }

  &__month-recap {
    cursor: pointer;
    display: flex;
    position: absolute;
    top: $paddingXBig;
    flex-direction: row;
    min-width: 230px;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;

    &--image-container {
      img {
        margin-right: 10px;
      }
    }

    &--text-container {
      display: flex;
      flex-direction: column;
    }

    &--month {
      color: #4D5054;
      font-size: 15px;
      text-transform: capitalize;
    }

    &--desc {
      text-decoration: underline;
      display: flex;
      font-size: 13px;
      color: #4D5054;
      flex-direction: column;
    }
  }

  &__desc-labels {
    width: 50%;
    min-width: 230px;
    font-size: 12px;
  }

  &__desc-label {
    text-transform: uppercase;

    &--events {}

    &--drafts {
      font-weight: bold;
      padding-top: 45px;
      padding-bottom: 45px;
      font-size: 25px;
      text-transform: capitalize;
    }

    &--changes {}
  }

  &__right-container {
    position: relative;
    display: flex;
    flex: 5;
    flex-direction: column;
    transition: transform 350ms ease;

    .timeline--open & {
      transform: translate3d(0, -40vh, 0);
    }

    &--top-element {
      display: flex;
      align-items: flex-end;
      flex: 2.5;
      z-index: 10;
    }

    &--calendar-element {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1.2;
      z-index: 1000;
    }

    &--bottom-element {
      display: flex;
      flex-direction: row;
      flex: 2.5;
      justify-content: space-between;
    }
  }

  &__calendar-date {
    position: relative;
    flex: 1;
    text-align: center;
    padding: 1px;
    font-size: 1.4vw;
    @media (min-width: 1260px) {
      font-size: 20px;
    }

    &.active {
      &:before {
        content: '';
        position: absolute;
        width: 2.4vw;
        height: 2.4vw;
        padding: 5px;
        background: $light_component_background;
        border: 1px solid #DCE1E5;
        border-radius: 100%;
        top: 50%;
        left: 50%;
        flex: 1;
        transform: translate3d(-50%, -50%, 0);
        z-index: -1;
        @media (min-width: 1260px) {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  &__calendar-change {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #D4D6D8;
    pointer-events: none;
    height: auto;
    min-width: 200px;

    &--major,
    &--minor {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-transform: uppercase;
      color: #D4D6D8;
      cursor: auto;
      pointer-events: none;
      background-color: $light_component_background;
      box-shadow: 0 5px 8px 0 rgba(0,0,0,0.08);
      border-radius: 3px;
      transition: transform 150ms ease-out, max-width 150ms ease-out;

      &.hasEvents {
        color: $black;
        cursor: pointer;
        pointer-events: all;
      }
    }

    &--major {
      display: flex;
      position: relative;
      width: 90%;
      max-width: 200px;
      height: 180px;
      max-height: 130px;
      font-size: 30px;
      // padding-top: 20px;
      // padding: 20px 0;
      &:after {
        content: '';
        width: 1px;
        height: 25px;
        background-color: #DCE1E5;
        position: absolute;
        left: 49%;
        bottom: -25px;
      }
    }

    &--minor {
      width: 50%;
      max-width: 80px;
      height: 66px;
      max-height: 66px;
      margin-top: 25px;
      z-index: 10;
    }

    &--number,
    &--title {
      transition: transform 150ms ease-out, opacity 100ms ease-out;

      .hasEvents:hover & {
        opacity: 0;
        transform: translate3d(0, -35px, 0) scale(0.9);
      }

      .hasEvents & {
        pointer-events: all;
      }
    }

    &__header-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      pointer-events: all;
      flex: 2;
      width: 100%;
      transform: translate3d(0, 10px, 0);
    }

    &__icons-container {
      flex: 1;

      .timeline__change-desc-icon--desc {
        transform: scale(0.1) translate3d(0, -35px, 0);
      }

      .hasEvents:hover & {
        width: 100%;
        // left: -10%;
        justify-content: space-around;

        .timeline__change-desc-icon--desc {
          opacity: 1;
          transform: scale(1) translate3d(0, -35px, 0);
        }
      }
    }

    &.hasEvents:hover {
      max-width: 220px;
    }

    &--number,
    &--title {
      opacity: 1;
      // transform: translate3d(0, 20px, 0) scale(1);
      // transition: transform 250ms 100ms ease, opacity 300ms 100ms ease;
    }

    &--details {}
  }

  &__calendar-change__icons-container {
    width: 100%;
    // min-width: 100px;
    // margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 5%;
    transition: padding 250ms ease;
  }

  &__change-desc-icon {
    // position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;

    img {
      transform: translate3d(0, -22px, 0);
    }

    &--desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      transition: opacity 100ms ease, transform 300ms ease;
      transform: scale(0.1) translate3d(0, 45px, 0);
    }

    &--desc_amount {
      font-size: 30px;
    }

    &--desc_type {
      font-size: 13px;
    }
  }

  &__key-event {
    position: absolute;
    width: auto;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.08);
    border-radius: 3px;
    padding: $paddingBig $paddingXBig $paddingBig $paddingBig;
    background-color: $light_component_background;
    cursor: pointer;
    transition: transform 350ms ease;

    &--header {
      position: relative;
      min-width: 100px;
      text-align: center;
    }

    &--icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 100%;
      top: 50%;
      width: 31px;
      height: 31px;
      transform: translate3d(-50%, -50%, 0);
      border: 1px solid #DCE1E5;
      border-radius: 50%;
      background-color: $light_component_background;
    }

    &--line {
      position: absolute;
      background-color: #DCE1E5;
      width: 1px;
      top: 100%;
      left: 49%;
      bottom: -25px;
    }
  }

  &__right-container--calendar-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    z-index: 20;
    z-index: 1000;
  }

  &__right-container--bottom-element {
    display: flex;
    flex-direction: row;
  }

  &__information-container {
    position: absolute;
    width: 100vw;
    height: 50vh;
    left: 0;
    top: 100%;
    z-index: 100;
    transition: transform 380ms 10ms ease;
    background-color: #EEF0F2;

    .timeline--open & {
      transform: translate3d(0, -50vh, 0);
    }
  }

  &__information-handle {
    position: absolute;
    display: flex;
    width: 300px;
    height: 30px;
    background-color: #EEF0F2;
    top: -30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-left: 1px solid $light_panel_background;
    border-top: 1px solid $light_panel_background;
    border-right: 1px solid $light_panel_background;
    left: calc(50% - 150px);
    align-items: center;
    justify-content: center;
    transition: transform 350ms ease;
    cursor: pointer;
  }

  &__info-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    height: 100%;
    border-top: 1px solid $light_panel_background;
    // padding-top: $paddingXBig;
  }

  &__info-wrapper-part {
    flex: 1;
    height: 100%;
    padding: 40px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 60px;
      top: calc(100% - 60px);
      z-index: 1000;
      background: linear-gradient(0deg, #EEF0F2 0%, rgba(238, 240, 242, 0) 100%);
      left: 0;
    }
  }

  &__info-part-header {
    position: relative;
    font-size: 25px;
    height: 50px;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $light_panel_background;
      top: 40px;
      left: 0;
    }
  }

  &__changes-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 40px;
    padding-right: 16px;

    &--list-major-change,
    &--list-minor-change {
      position: relative;
      cursor: pointer;
      margin-bottom: 20px;

      div {
        user-select: all;
      }
    }

    &--list-minor-change {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 3px;
        height: 4px;
        margin-right: $paddingNormal;
      }

      > header {
        position: relative;
        font-size: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          height: 2px;
          width: 100%;
          background-color: $black;
        }

        span {
          font-size: 18px;
        }
      }

      > div {
        // margin-top: $paddingNormal;
        // padding-left: $paddingNormal;
        align-self: flex-start;
      }
    }

    &--list-major-change {
      div {
        margin-top: $paddingNormal;
        font-size: 15px;
      }

      .arrow-icon {
        top: 8px;
        margin-left: $paddingNormal;
      }
    }

    &--list-change-header {}
  }
}

.date-changes {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 54px;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: $light_panel_background;
    top: 40px;
    left: 0;
  }

  &:hover {
    &:after {
      height: 2px;
    }
  }

  > div {
    height: 100%;
  }

  &__date {
    font-size: 30px;
  }
}