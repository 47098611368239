@import 'Login/Login';
@import 'Scheduler/Scheduler';
@import 'ManageContent/ManageContent';
@import 'Timeline/Timeline';


.generic-page{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  flex-direction: column;


  &__heading{
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  &__subheading{
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin-top: 10px;
  }
  &__link{
    width: auto;
    padding: 8px 12px;
    border: 1px solid #1c2226;
    text-align: center;
    align-items: normal;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
    transition: all calc(250ms / 2) cubic-bezier(0.425, 0.42, 0, 0.755);
    margin-top: 40px;
    color: rgba(28,34,38, 1);
  }
}