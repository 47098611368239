$easing: cubic-bezier(.25,1.7,.35,.8);
$duration: 0.5s;

.SchedulerTopVersion,
.SchedulerTopVersionTimeline {
  &:hover {
    .arrow-icon {
      opacity: 1;
    }
  }
}

.arrow-icon {
  background-color: transparent;
  // opacity: 0.4;
  transition: opacity 200ms $easeInOut;
  height: 20px;
  width: 24px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.arrow-icon.open {
  opacity: 1;
}

.left-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 12px;
  height: 4px;
  transition: all $duration $easing;
  display: block;
  transform: rotate(40deg);
  float: right;
  border-radius: 3px;

  &:after {
    content: "";
    background-color: $black;
    width: 12px;
    height: 1px;
    display: block;
    float: right;
    border-radius: 6px 10px 10px 6px;
    transition: all $duration $easing;
    z-index: -1;

    .darkmode & {
      background-color: $white;
    }
  }

  .white & {
    &:after {
      background-color: $white;
    }
  }
}

.right-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 11px;
  width: 12px;
  height: 4px;
  transition: all $duration $easing;
  display: block;
  transform: rotate(-40deg);
  float: right;
  border-radius: 2px;

  &:after {
    content: "";
    background-color: $black;
    width: 12px;
    height: 1px;
    display: block;
    float: right;
    border-radius: 10px 6px 6px 10px;
    transition: all $duration $easing;
    z-index: -1;

    .darkmode & {
      background-color: $white;
    }
  }

  .white & {
    &:after {
      background-color: $white;
    }
  }
}

.open {
  .left-bar:after {
    transform-origin: center center;
    transform: rotate(-80deg);
  }

  .right-bar:after {
    transform-origin: center center;
    transform: rotate(80deg);
  }
}