.ManageContent {
  // margin-top: 60px;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
}

.ManageContent__Sidebar {
  display: flex;
  width: 40%;
  max-width: 400px;
  max-height: calc(100vh - 60px);
  flex: 1;
  justify-content: top;
  align-items: flex-end;
  padding-right: 20px;
  flex-direction: column;
  padding-top: 120px;
}

.ManageContent__SidebarLink {
  margin: 0 0 20px;
  color: $black;
  text-decoration: none;
  font-size: 18px;

  &.active {
    font-weight: bold;
  }
}

.ManageContent__Content {
  background: $white;
  flex: 1;
  padding: 60px 0 0 60px;
  max-height: calc(100vh - 60px);
}

.ContentList {
  max-width: 500px;
  height: 100%;

  &.HALF {
    height: auto;
    min-height: 10vh;
    max-height: 49vh;
    margin-bottom: 1vh;

    .ContentList__Content {
      max-height: 42vh;
      overflow: auto;
    }
  }
}

.ContentList__HeaderWrapper {
  border-bottom: 2px solid $black;
  padding: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ContentList__Header {
  font-weight: bold;
  font-size: 26px;
}

.ContentList__RemoveBtn {
  font-size: 13px;
  color: #1C2226;
  padding: 6px 25px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #B2C2CC;
  display: none;
  width: 0;

  &.show {
    display: inline;
    pointer-events: all;
    cursor: pointer;
    width: auto;

    &:hover {
      font-weight: bold;
    }
  }
}

.ContentList__Search {
  font-size: 13px;
  color: #1C2226;
  padding: 6px 25px;
  border-radius: 3px;
  border: 1px solid #B2C2CC;
  transition: all 0.2s;
  width: 120px;
  background: url("~../../assets/images/svg/icons/Search.svg") no-repeat 6px center;
}

.ContentList__Content {
  max-height: 90%;
  overflow-y: scroll;
  border-bottom: 2px solid $black;
}

.ContentList__ListItem {
  padding: 15px 0 12px;
  border-bottom: 1px solid #B2C2CC;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .ContentList__ItemTitle {
      font-weight: bold;
    }
  }

  .ContentList__ListItem__Timeframe {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    padding-top: 5px;
  }
  .ContentList__ListItem__Chip {
    display: inline;
    font-size: 0.7em;
    padding: 2px 8px;
    background: white;
    border-radius: 0.9em;
    margin-left: 1em;
    border: 1px solid $light_stroke;
  }
}

.ContentList__ItemTitle {
  img {
    margin-right: 8px;
    position: relative;
    top: 2px;
  }
}

.ContentList__EditBtn {
  font-size: 13px;
  color: #1C2226;
  padding: 6px 25px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #B2C2CC;
  opacity: 0;
  transition: all 0.2s;

  &:hover {
    font-weight: bold;
  }
}

.ContentList__ListItem:hover {
  .ContentList__EditBtn {
    opacity: 1;
  }
}


.ContentList__Footer {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  gap: 10px;

  div {
    display: flex;
  justify-content: flex-end;
  gap: 10px;
  }
}