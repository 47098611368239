.Gradient_Background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: all 250ms ease-in-out;
  background: rgb(255,255,255);
  // background: linear-gradient(90deg, rgba(255,0,255,1) 0%, rgba(0,238,240,1) 100%);
  // background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(236,238,240,1) 80%, rgba(236,238,240,0) 80%);
}