.PSelector--Button {
  flex: 1;
  cursor: pointer;
  color: rgba(28,34,38,0.55);
  width: 60px;
  padding: 2px;
  text-align: center;
  @include fastTransitionInOut;
  min-width: 60px;

  &:hover {
    font-weight: bold;
    color: $black;
  }

  &.active {
    @extend .FontStyle--Bold;
    color: $black;
  }
}

// New way, set left border for all buttons that has a button before it (all buttons except the first)
.PSelector--Button + .PSelector--Button {
  border-left: 1px solid $light_borderBlack;
}

/* Old way, static setting for middle button
.PSelector--Button:nth-child(2) {
  border-left: 1px solid $light_borderBlack;
  border-right: 1px solid $light_borderBlack;
}
*/
