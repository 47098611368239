.Dates {
  position: relative;
  display: flex;
  width: auto;
  height: $datesHeight;
  align-items: flex-end;
  z-index: 3;

  .darkmode & {
    color: #B2C2CC;
    // .Month {
    //   .Month--Title {
    //     span {
    //       background-color: $color-darkmode;
    //     }
    //   }
    // }
  }

  .Year {
    display: flex;
    flex-direction: column;
    background-color: transparent;

    &:after {
      content: '';
      position: absolute;
      top: 46px;
      transform: translate3d(-1px, 0, 0);
      width: 1px;
      height: 100vh;
      background-color: $light_stroke;

      .darkmode & {
        background-color: #B2C2CC;
        opacity: 0.5;
      }
    }

    .Year--Title {
      text-align: center;
      flex: 1;
      font-size: 13px;
    }

    .Year--Months {
      display: flex;
      flex-direction: row;
      flex: 2;
    }
  }

  .Month--Width,
  .Year--Width {
    position: relative;
  }

  .Month {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background-color: transparent;

    .Month--Days,
    .Month--Title {
      flex: 0.5;
      text-transform: uppercase;
    }

    .Month--Title {
      z-index: 10;
      // background-color: transparent;
      background: $light_component_background;
      text-align: center;

      .darkmode & {
        background: #393939;
      }
      // span {
      //   padding: 2px;
      //   background-color: $light_component_background;
      // }
    }

    &.current {
      .Month--Title {
        font-weight: bold;
      }
    }

    .Month--Days {
      display: flex;

      &:empty {
        display: none;
      }
    }
  }

  .Day {
    display: inline-block;
    flex: 1;
    display: inline-block;
    text-align: center;
    // box-shadow: -1px 0 0 #fff;
  }
}
