.SchedulerTop_Filters {
  display: flex;
  width: 100%; // calc(100% - #{$rigListContainer--Width});
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  padding: 20px 10px;
  margin: 0 auto;
  min-height: 120px;
  @media screen and (min-width: 1100px) {
    // calc(100% - #{$rigListContainer--Width});
  }

  @media screen and (min-width: 1270px) {
    padding: 20px 0 20px 25px;
    width: 1170px;
  }

  .RoundedDropDown {
    height: 35px;
    margin-right: 10px;
    width: 130px;

    .darkmode & {
      border-color: #535556;
      background: #2d3032;
      color: #d4d6da;
    }

    @media screen and (min-width: 1270px) {
      margin-right: 0;
      width: 200px;
    }
  }

  .RoundedDropDown .RoundedDropDown--Elements--Container {
    .darkmode & {
      background: #2d3032;
      color: #d4d6da;
      border-color: #535556;
    }
  }

  .SchedulerTop__Zoom {
    position: relative;
    display: flex;
    min-height: 50px;
    height: 100%;
    border-radius: $borderRadius;
    width: auto;
    align-items: flex-end;

    @media screen and (min-width: 1270px) {
      min-width: 380px;
    }

    .SchedulerTop__Zoom__Selector__Container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }

    .PSelector {
      display: flex;
      background-color: $white;
      border-radius: $borderRadius;
      height: 24px;
      align-items: center;
      margin-bottom: 10px;
    }

    .SchedulerTop__Zoom--years {
      padding: 8px 4px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      flex-direction: column;
      border: 1px solid $light_gray;
      border-radius: $borderRadius;
      height: 44px;
      justify-content: space-between;

      .darkmode & {
        border-color: #535556;
      }

      &.active {
        background: $white;
      }

      .darkmode & {
        background: none;
      }

      .SchedulerTop__Zoom--years_desc {
        display: inline-block;
        padding-left: 10px;
        font-weight: bold;
      }

      .SchedulerTop__Zoom--years_buttons {
        display: flex;
        margin-left: 25px;
        @media screen and (min-width: 1100px) {
          display: inline-block;
        }
      }
    }

    .SchedulerTop__Zoom--years_buttonsWrapper {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .SchedulerTop__Zoom--month_button {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-radius: $borderRadius;
      text-transform: uppercase;
      height: 43px;
      min-width: 120px;
      margin: 10px 0 0 10px;
      border: 1px solid $light_gray;

      .darkmode & {
        border-color: #535556;
      }

      .Zoom--Button {
        width: 100%;
      }

      &.active {
        background: $white;
      }
    }
  }

  .SchedulerTop__Date {
    display: flex;
    min-width: 235px;
    min-height: 50px;
    padding: 33px 10px 0;
    border-radius: $borderRadius;
    margin: 0 10px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (min-width: 1100px) {
      margin: 0 30px;
      padding: 33px 20px 0;
    }
  }

  .SchedulerTop__DateLabel {
    text-transform: uppercase;
    font-size: 11px;
    display: block;
  }

  .SchedulerTop__DateRange {
    font-size: 35px;
    font-weight: 900;
    line-height: 38px;
  }

  .SchedulerTop__Filter {
    display: flex;
    min-width: 500px;
    min-height: 100%;
    flex-direction: column;
    flex: 1;

    .darkmode & {
      .RoundedDropDown {
        .close {
          border-color: #d4d6da !important;

          span::before,
          span::after {
            background: #d4d6da !important;
          }
        }
      }
    }

    .Row__Container {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      justify-content: flex-start;

      @media screen and (min-width: 1270px) {
        justify-content: space-between;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .SchedulerTop__FilterSearchWrapper {
    position: relative;
    width: 220px;

    @media screen and (min-width: 1270px) {
      flex: 1;
      margin-left: 10px;
      width: auto;
    }

    svg {
      position: absolute;
      top: 9px;
      left: 11px;
    }
  }

  .SchedulerTop__FilterBtn {
    border-radius: $borderRadius;
    border: 1px solid $light_gray;
    background: transparent;
    color: $black;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .darkmode & {
      border-color: #535556;
    }

    img {
      display: block;
      // height: 20px;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }

    &.active {
      background-color: $white;
    }

    &:first-of-type {
      margin-right: 10px;
      @media screen and (min-width: 1270px) {
        margin-right: 0;
      }
    }
  }

  .SchedulerTop__FilterSearch {
    border-radius: $borderRadius;
    border: 1px solid $light_gray;
    width: 100%;
    height: 35px;
    background: transparent;
    color: $black;
    padding: 10px;
    font-size: 14px;
    @extend .FontStyle--Regular;
    padding-left: 30px;

    .darkmode & {
      border-color: #535556;
    }

    &::placeholder {
      color: $black;

      .darkmode & {
        color: #d4d6da;
      }
    }

    &.hasSearch {
      background: $white;
      font-weight: bold;
    }
  }
}
