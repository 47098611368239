.CircleCrossButton {
  width: 50px;
  height: 50px;
  top: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .CircleCrossButton__CIRCLE {
    border-radius: 50%;
    border: 1px solid #B2C2CC;
    height: 33px;
    width: 33px;
    transition: all 200ms $easeInOut;

    &:hover {
      height: 40px;
      width: 40px;
    }
  }

  .CircleCrossButton__CROSS {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate3d(-50%, -50%, 0);
  }

  .CircleCrossButton__CROSS:after,
  .CircleCrossButton__CROSS:before {
    content: ' ';
    position: absolute;
    background-color: $black;
  }

  .CircleCrossButton__CROSS:before {
    left: 4px;
    height: 10px;
    width: 2px;
  }

  .CircleCrossButton__CROSS:after {
    top: 4px;
    height: 2px;
    width: 10px;
  }
}