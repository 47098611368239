/*

REGULAR FADE

*/
.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1500ms 2000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}
// ANIMATE RIGHT
@keyframes BounceRight {
  0% {
    transform: translate3d(-3px, 0, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-3px, 0, 0);
  }
}