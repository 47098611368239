.Panel__Button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 45px;
  border: 0;
  font-size: 14px;
  color: $white;
  cursor: pointer;
  backface-visibility: hidden;
  transition: all $regularTransition $easeInOut;
  background-color: $white;
  color: $light_panel_background;

  &.invalid {
    pointer-events: none;
    background-color: gray !important;
    color: $white;
  }

  &.Panel__Button--cancel {
    background-color: $light_panel_background;
    color: $white;
  }

  &.Panel__Button--next {}

  &.Panel__Button--submit {

    &.Panel__Button--submit--in-progress{

    }
  }
}
