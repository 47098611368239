.ReactModal__Content {
  background: transparent;
  z-index: 999;
  border-radius: $borderRadius;
  max-height: 90vh;
  max-width: 60vw;
  min-width: 450px;
  overflow: visible;
  @extend .FontStyle--Regular;
  background-color: white;
  &:focus {
    outline: none;
  }

  &.ReactModal__Content--large {
    height: 80vh;
    width: 550px;
    max-height: 800px;
  }

  &.ReactModal__Content--scroll {
    position: relative;
    .ReactModal__Wrapper{
      overflow: scroll;
      max-height: 90vh;
      padding-bottom: 30px;
    }

    .ReactModal__Buttons{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50px;
    }
  }

  &.ReactModal__Content--wide {
    height: 80vh;
    width: 80vw;
    min-width: 650px;
    max-width: 1300px;
    max-height: 800px;
    box-shadow: #333333 0 0 10px;

    &.errorConsoleOpen {
      width: calc(80vw - 200px);
      transform: translate3d(-100px, 0, 0);
    }
  }

  &.ReactModal__Content--tall {
    height: 80vh;
    width: 60vw;
    max-width: 800px;
    max-height: 80vh;
  }

  &.ReactModal__Content--free {
    position: absolute;
    pointer-events: all;
    transform: translate3d(140px, 156px, 0);
  }
}

.ReactModal__Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ReactModal__Form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
}

.ReactModal__FormHeader {
  font-size: 18px;
  width: 85%;
  text-align: center;

  &.ReactModal__FormHeader--big {
    @extend .FontStyle--Bold;
    font-size: 24px;
  }
}

.ReactModal__BigInput {
  font-size: 25px;
  border: 0;
  text-align: center;
  border-bottom: 1px solid $black;
  margin-top: 8px;
  padding-bottom: 4px;

  &:focus {
    outline: none;
  }
}

.ReactModal__Buttons {
  display: flex;
  flex-basis: 50px;
}

.ReactModal__Button {
  flex: 1;
  border: none;
  border-top: 1px solid $black;
  right: 1px solid $black;
  background: $white;
  font-size: 15px;
  @extend .FontStyle--Regular;

  &:focus {
    outline: none;
  }
  cursor: pointer;

  &:hover {
    color: #7D919F;
  }

  &:last-of-type {
    border-right: 0;
  }

  &.ReactModal__Button--primary {
    background: $black;
    color: $white;

    &:hover {
      color: #BDC7CB;
    }
  }

  &.ReactModal__Button--inactive {
    opacity: 0.2;
  }
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(28, 34, 38, 0.8);
  background-color: rgba(200, 200, 200, 0.8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Modal__Overlay--free {
    background-color: transparent;
    display: block;
    position: absolute;
    pointer-events: none;
  }

  .Modal__Overlay--max-height {
    max-height: 750px;
  }
}

.ModalForm {
  padding: 30px;
  text-align: left;

  h2 {
    text-align: left;
    font-weight: bold;
    font-size: 22px;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 2px solid $black;
  }
}

.ModalForm__Part {
  margin: 0 0 30px;
  width: 100%;

  &.TALL {
    height: 150px;

    .ModalForm__Input {
      height: 150px;
      border: 1px solid #1c2226;
      resize: none;
    }
  }

  .ModalForm__Date--Container {
    display: flex;
    flex-direction: column;
  }
}

.ReactModal__User {
  margin-bottom: 4px;
  position: relative;
}

.ReactModal__RemoveUser {
  position: absolute;
  right: 5px;
  top: 12px;
  font-size: 12px;
  cursor: pointer;
}

.ModalForm__AddBtn {
  display: block;
  text-align: center;
  width: 200px;
  margin: 0 auto;
  border: 1px solid;
  border-radius: $borderRadius;
  padding: 8px 0;
  cursor: pointer;
}

.ModalForm__Scenarios {
  display: flex;
}
.ModalForm__Scenario {
  flex: 1;
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.9rem;
  }

  .ModalForm__Input {
    width: 100px;
  }
}

.ModalForm__WellStatusMappings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ModalForm__WellStatusMapping {
  flex: 1;
}

.ModalForm__AppSettings{
  .Select__Dropdown{
    width: 100%;
  }
}

.ModalForm__Label {
  text-transform: uppercase;
  font-size: 13px;
  width: 100%;
  display: block;
  margin: 0 0 5px;
}

.ModalForm__RadioWrapper {
  label {
    display: flex;
    margin-bottom: 4px;
  }

  input {
    flex: 0 0 25px;
  }

  span {}
}

.ModalForm__Input {
  border: 1px solid $black;
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 16px;
  width: 100%;

  &.invalid {
    border-color: $red;
  }
}

.ModalForm__AdditionalInfo{
  font-size: 0.7rem;
  display: block;
  margin: 10px 0 0 0;
  text-align: center;
}


.OwnerInterest{

}
.OwnerInterest__row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &--head{
    background: #ededed;
  }

  &:hover{
    .OwnerInterest__remove{
      display: block;
    }
  }
}
.OwnerInterest__cell{
  // padding: 5px;
  border: 1px solid #ededed;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  &:last-of-type{
    flex: 0 0 100px;
  }

  .OwnerInterest__row--head & {
    padding: 10px 12px;

    span{
      font-size: 0.9rem;line-height: 1rem;
    }
  }
}
.OwnerInterest__text{

}
.OwnerInterest__input{
  padding: 12px;
  height: 35px;
  width: 100%;
  background-color: #fafafa;
  
  &--ours-label{
    background: #fff;
    font-style: italic;
  }

  &--interest{
    width: 100px;
    font-size: 1rem;
    line-height: 0.8rem;
  }
}
.OwnerInterest__remove{
  font-size: 0.7rem;
  position: absolute;
  right: 4px;
  top: 8px;
  display: block;
  display: none;
  text-transform: uppercase;
  padding: 4px 8px;
  border: none;
  cursor: pointer;
}
.OwnerInterest__add{
  font-size: 0.7rem;
  margin-left: 10px;
  display: block;
  text-transform: uppercase;
  padding: 6px 8px 3px;
  border: none;
  cursor: pointer;
}