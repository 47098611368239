.New-Well-Button {
  position: absolute;
  display: flex;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 20px;
  cursor: pointer;
  z-index: 1;
  border-right: 1px solid white;
  align-items: center;
  width: 120px;

  &:hover {
    font-weight: bold;
  }
}