.Scheduler--Overview {
  display: flex;
  flex: 6;
  padding: 12px 0 0 25px;
  background: $light_component_background;
  min-height: 0;

  .darkmode & {
    background: $color-darkmode;
  }
}
@import 'SchedulerRigListContainer';
@import 'SchedulerRigPlotterContainer';