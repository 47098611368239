.Zoom--Button {
  display: inline-flex;
  width: auto;
  height: 25px;
  padding: 5px;
  border-right: 1px solid $black;
  color: rgba(28,34,38,0.55);
  // width: 100%;
  text-align: center;
  align-items: normal;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  @media screen and (min-width: 1100px) {
    width: 30px;
  }
  @include fastTransitionInOut;

  &:hover {
    font-weight: bold;
    color: $black;
  }

  &.active {
    @extend .FontStyle--Bold;
    color: $black;
  }

  &:last-of-type {
    border-right: none;
  }

  .darkmode & {
    color: #D4D6DA;
    border-right: 1px solid #D4D6DA;
    &:last-of-type {
      border-right: none;
    }
  }
}
