.tooltip{
  position: absolute;
  background: #434649;
  border-radius: 3px;
  color: $white;
  text-transform: uppercase;
  opacity: 0;
  pointer-events: none;
  padding: 3px 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: opacity 400ms ease;

  &::after{
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 7px 0 7px;
    border-color: #434649 transparent transparent transparent;
    top: 19px;
    position: absolute;
  }
}
  .tooltip--visible{
    opacity: 1;
  }

  .tooltip-text{
    font-size: 11px;
    line-height: 16px;
  }


.DNDC__Singlewell{
  .tooltip{
    top: -54px;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
  }
}
