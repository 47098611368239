.write-desicion-summary {
  color: $black;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }

  textarea {
    vertical-align: top;
    width: 100%;
    height: 250px;
    padding: 10px;
    resize: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.desicion-summary {
  position: absolute;
  height: calc(100% - 20px);
  max-width: 800px;
  width: 100%;
  // padding: 0 20px;
  &__confirm-message {}

  &__header {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: flex-end;
    transform: translate3d(0, -20px, 0);
    width: calc(100% - 60px);
    width: 100%;

    &--left-wrapper {
      display: flex;
      flex-direction: row;
    }

    &--pages {}

    &--edited {}

    &--close {
      cursor: pointer;
    }

    &--export {
      cursor: pointer;
    }
  }

  &__content-wrapper {
    position: relative;
    height: calc(100% - 20px);
    width: 100%;
    overflow: hidden;
    padding: 10px 0 0 10px;

    h1 {
      font-size: 25px;
      color: #1C2226;
    }

    &--sender {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $paddingBig;
      width: calc(100% - 30px);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: calc(100% + 6px);
        background-color: #1C2226;
        height: 1px;
        width: 100%;
      }
    }

    &--from {
      user-select: text;
    }

    &--date {
      user-select: text;
    }
  }

  &__content {
    width: 100%;
    height: calc(100% - 65px);
    white-space: pre-wrap;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 40px 20px 0;
    user-select: text;
  }
}

.Panel {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 300px;
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-color: $light_panel_background;

  &.hasError {
    animation: form-error 0.4s 1 linear;
  }

  .ReactModal__Content--white & {
    background: $light_component_background;
    color: $black;
  }

  .Panel__Header {
    display: flex;
    flex-basis: 50px;
    flex-direction: row;
    border-bottom: 1px solid $light_component_background;

    &.white {
      border-bottom: 1px solid $light_gray;
    }

    &.narrow {
      .Panel__Header-Info {
        padding-left: $paddingXBig;
      }
    }

    &.noBorder {
      border: none;
    }

    &.hasMiddleComponent {
      & > div {
        box-sizing: border-box;
        flex: 1 !important;
      }
    }

    &.wide {
      .Panel__Header-Info {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .Panel__Header-Sub-Heading {
          font-size: 20px;
          @extend .FontStyle--Bold;
        }

        .Panel__Header-Heading {
          font-size: 20px;
          font-weight: 400;
          text-indent: $paddingNormal;
        }
      }
    }

    .Panel__Header-Middle {}

    .Panel-pselector {
      padding: $paddingNormal;
    }

    .Panel-pselector__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Panel-pselector__link {
      background: $white;
      color: $black;
      cursor: pointer;
      text-align: center;
      padding: 6px 0;
      width: 100px;

      &.active {
        font-weight: bold;
      }

      &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: 1px solid $black;
      }

      &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: 1px solid $black;
      }
    }

    .Panel__Header-Info {
      flex: 3;
      color: $white;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: $paddingBig;
      max-width: calc(100% - 200px);

      .ReactModal__Content--white & {
        color: $black;
      }

      .Panel__Header-Sub-Heading {
        font-size: 15px;
        white-space: nowrap;
      }

      .Panel__Header-Heading {
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @extend .FontStyle--Bold;
      }
    }
  }

  .Panel__Header-Buttons {
    display: flex;
    justify-content: flex-end;
    padding-left: 40px;
    flex: 1;
  }

  .Panel__Header-Button {
    background: none;
    border: none;
    border-left: 1px solid $white;
    color: $white;
    font-size: 14px;
    padding: 0 25px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: $white;
      color: $black;

      circle,
      svg {
        fill: $black;
      }

      path {
        stroke: $black;
      }
    }

    &--p1{
      padding: 0;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 14px;
        cursor: pointer;
        background: none;
        height: 100%;
        padding: 0 25px;

        &:hover {
          background: $white;
          color: $black;
        }
  
        img{
          height: 16px;
          display: block;
          margin: 0 8px 0 0;
          position: relative;
          bottom: -3px;
        }
      }
    }
  }

  .Panel__Page {
    display: flex;
    color: $white;
    flex: 1;
    flex-basis: -45px;
    justify-content: center;
    padding: 0 5%;
    font-size: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    &.noOverflowHidden {
      overflow: visible;
    }

    &.noPadding {
      padding: 0;
    }

    &__desicion-summary {
      width: 100%;
      color: $black;
    }

    &.Panel-Share {
      width: 660px;
      height: 400px;
      padding-left: 40px;
      padding-right: 40px;

      .menu {
        color: black;

        > div {
          cursor: pointer;
        }
      }
    }

    &.Panel-Campaign {
      padding-left: $paddingSmall;
      padding-right: $paddingSmall;
      overflow-y: hidden;

      .Panel__Page-Container {
        padding: 0;
      }
    }

    .Panel__Page-Container {
      flex: 1;
      display: block;
      padding: 5% 0;
      width: 100%;

      .Header {
        margin-bottom: 10px;
      }

      .Radio--Container {
        > div {
          margin-bottom: 5px;
        }

        input {
          margin-right: 5px;
        }
      }
    }
  }

  .Panel__Footer {
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: row;
    bottom: 0;
    flex-wrap: wrap;
    border-top: 1px solid $light_component_background;

    .ReactModal__Content--white & {
      border-top: 1px solid $black;
    }
  }

  .Panel__Footer-Error {
    background: rgba(252, 86, 68, 1);
    color: $white;
    text-align: center;
    font-size: 13px;
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    opacity: 0;
    height: 0;
    transition: all 0.3s;

    &.Panel__Footer-Error--visible {
      opacity: 1;
      height: 30px;
    }
  }
}

.UpdateShareOfDraft {
  display: flex;
  flex-direction: column;
  color: $black;

  .UpdateShareOfDraft__Header {
    height: 30px;

    .title {
      font-size: 15px;
      margin-bottom: $paddingNormal;
    }

    .name {
      font-size: 30px;
      font-style: bold;
    }
  }

  .sharegroup {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 150px;

    > div {
      height: 100%;
      padding: $paddingNormal;
      border: 1px solid $gray;
      border-radius: $borderRadius;
    }

    .Shares {
      margin-right: $paddingSmall;
      flex: 80;
      overflow-x: hidden;
      overflow-y: auto;

      .Share__Element {
        max-width: 48%;
        min-width: 48%;
      }

      input {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $gray;
        margin-right: 5px;
      }
    }

    .addGroup {
      margin-left: $paddingSmall;
      flex: 20;
      border: none;
      padding-top: 0;
      padding-right: 0;
    }
  }

  .addComment {
    position: relative;
    display: flex;
    height: 100px;
    padding: $paddingNormal;
    border: 1px solid $gray;
    border-radius: $borderRadius;
    margin-top: $paddingNormal;

    textarea {
      flex: 1;
      padding: $paddingNormal;
      outline: none;
      border: none;
      vertical-align: top;
      text-align: left;
      resize: none;
      background-color: transparent;
    }
  }

  .UpdateShareOfDraft__Desc {
    height: 40px;

    .Desc__Title {
      font-size: 20;
      font-weight: bold;
    }

    .Desc__Ingres {
      font-size: 15px;
      color: $light_panel_background;
    }
  }

  .UpdateShareOfDraft__List {
    height: 200px;
  }
}

.ConfirmMessage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  height: 80%;
  padding-top: 15px;

  // svg {
  //   margin-bottom: 20px;
  // }
}

.ConfirmMessage__Header {
  font-size: 30px;
  color: $black;
}

.ConfirmMessage__SubHeader {
  font-size: 20px;
  color: $black;
}
.ConfirmMessage__Label {
  color: $black;
}
.ConfirmMessage__SelectWrapper {
  margin: 30px 0 10px;
  width: 100%;
  display: flex;
}
.ConfirmMessage__Select {
  width: 100%;
  padding-left: 5px;



  &:first-of-type {
    padding-right: 5px;
    padding-left: 0;
  }
}
.ConfirmMessage__SelectExplanation {
  font-size: 0.8rem;
  font-style: italic;
  p {
    margin: 0;
    padding: 0;
    color: $black;
  }
}


@keyframes form-error {
  0% {
    -webkit-transform: translate(30px);
  }

  20% {
    -webkit-transform: translate(-30px);
  }

  40% {
    -webkit-transform: translate(15px);
  }

  60% {
    -webkit-transform: translate(-15px);
  }

  80% {
    -webkit-transform: translate(8px);
  }

  100% {
    -webkit-transform: translate(0px);
  }
}
@import 'PanelForm/PanelForm';