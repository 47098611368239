.Requestlist-Preview {
  position: absolute;
  top: 3px;
  width: 60px;
  left: 0;
  height: calc(100% - 100px);

  &:after {
    content: " ";
    height: 60px;
    position: absolute;
    bottom: 84px;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(245,248,249,0) 0%,rgba(245,248,249,1) 100%);
  }
}

.Requestlist-Preview__item {
  margin: 0 2px 2px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}

.Requestlist-Preview__time {
  font-size: 20px;
}

.Requestlist-Preview__label {
  text-transform: uppercase;
  font-size: 10px;
}
