.WellPopover {
  background-color: #FFECBC;
  border-radius: $borderRadius;
  position: relative;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.20);
}

.WellPopover__Container {
  position: absolute;
  width: 300px;
  z-index: 999;
}

.WellPopover__Inner {
  overflow-y: scroll;    
  // height: 240px;
  max-height: 40vh;
}

.WellPopover:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-bottom-color: #FFECBC;
  border-width: 10px;
  margin-left: -10px;
}

.WellPopover.hideArrow:after {
  visibility: hidden;
}

.WellPopover.right:after {
  right: 10px;
  left: auto;
}

.WellPopover.left:after {
  left: 20px;
}

.WellPopover--buffer_time {
  background: rgb(218, 240, 255);
}

.WellPopover--execution {
  background: rgb(209, 255, 230);

  .darkmode & {
    background: $color-execution-darkmode;
  }

  &::after {
    border-bottom-color: rgb(209, 255, 230);
  }
}

.WellPopover--conceptional {
  background: $color-conceptional;

  .darkmode & {
    background: $color-conceptional-darkmode;
  }

  &::after {
    border-bottom-color: $color-conceptional;

    .darkmode & {
      border-bottom-color: $color-conceptional-darkmode;
    }
  }
}

.WellPopover--plan_and_select {
  background: $color-plan-and-select;

  .darkmode & {
    background: $color-plan-and-select-darkmode;
  }

  &::after {
    border-bottom-color: $color-plan-and-select;

    .darkmode & {
      border-bottom-color: $color-plan-and-select-darkmode;
    }
  }
}

.WellPopover--engineering_and_design {
  background: $color-engineering-and-design;

  .darkmode & {
    background: $color-engineering-and-design-darkmode;
  }

  &::after {
    border-bottom-color: $color-engineering-and-design;

    .darkmode & {
      border-bottom-color: $color-engineering-and-design-darkmode;
    }
  }
}

.WellPopover--plan_for_execution {
  background: $color-plan-for-execution;
  .darkmode & {
    background: $color-plan-for-execution-darkmode;
  }

  &::after {
    border-bottom-color: $color-plan-for-execution;
    .darkmode & {
      border-bottom-color: $color-plan-for-execution-darkmode;
    }
  }
}

.WellPopover--occupied {
  background: $color-occupied;

  .darkmode & {
    background: $color-occupied-darkmode;
  }

  &::after {
    border-bottom-color: $color-occupied;
    .darkmode & {
      border-bottom-color: $color-occupied-darkmode;
    }
  }
}
.WellPopover--additional_scope {
  background: #FFDAB9;

  .darkmode & {
    background: #FFDAB9;
  }

  &::after {
    border-bottom-color: #FFDAB9;
    .darkmode & {
      border-bottom-color: #FFDAB9;
    }
  }
}

.WellPopover--completed {
  background: $color-completed;

  &::after {
    border-bottom-color: $color-completed;
  }
}

.WellPopover__OverridePValue {
  display: block;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.WellPopover__Header {
  display: flex;
  padding: 15px 15px 0;
  height: 50px;
}

.WellPopover__Heading {
  flex: 1;
}

.WellPopover__Location {
  flex: 1;
  flex-direction: column;
  margin-top: 25px;
  font-size: 12px;

  .WellPopover__Location--Link {
    cursor: pointer;
    font-size: 11px;
    color: inherit;
    text-decoration: none;
  }
}

.WellPopover__Expected {
  margin-top: 25px;
  font-size: 12px;
  padding-left: 15px;
}
.WellPopover__ExpectedPart{
  display: flex;
  flex-direction: row;

  span:first-of-type{
    margin-right: 6px;
    font-weight: bold;
  }

}

.WellPopover__HeadingDuration {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  img {
    margin: 0 2px 0 1px;
  }
}

.WellPopover__Headline {
  @extend .FontStyle--Bold;
  font-size: 20px;
  line-height: 20px;
}

.WellPopover__SubHeading {
  font-size: 16px;
}

.WellPopover__SubHeading--small {
  font-size: 12px;
}

.WellPopover__Duration {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 60px;

  .Well__Duration {}
}

.WellPopover__DurationText {
  font-size: 25px;
  display: block;
  line-height: 34px;
  text-align: center;

  
}

.WellPopover__DurationLabel {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 10px;
  display: block;
}

.WellPopover__TotalDurationLabel {
  // position: absolute;
  text-transform: uppercase;
  font-size: 9px;
  // top: 35px;
  // right: 0;
  // width: 100px;
  text-align: right;
  padding-top: 8px;
  display: block;
}

.WellPopover__Content {
  margin: 15px 0;
  padding: 0 15px;
  padding-bottom: 40px;
}
.WellPopover__Content *{
  user-select: text;
}

.WellPopover__ContentPart {
  margin-top: 25px;
}

.WellPopover__ContentPart--twofold {
  display: flex;
  flex-direction: row;
  align-items: center;

  .WellPopover__ContentText:first-of-type {
    font-size: 11px;
    flex: 1;
  }

  .WellPopover__ContentText:last-of-type {
    text-align: right;
  }
}

.WellPopover__ContentHeadline {
  @extend .FontStyle--Bold;
  font-size: 12px;
}

.WellPopover__ContentText {
  font-size: 12px;
}

.WellPopover__WellStartDate {
  display: block;
  font-size: 11px;
  font-weight: bold;
}

.WellPopover__ContentLocation {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  .WellPopover__ContentLocation--Header {
    font-size: 12px;
  }
}

.WellPopover__ContentText--comment {
  white-space: pre-wrap;
}

.WellPopover__ContentText--rigmove {
  border: 1px solid $light_component_background;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-right: 0;
  padding: 0 4px;
}

.Popover-Estimate {
  display: flex;
  flex: 0;

  position: relative;
  right: -20px;
}

.Popover-Estimate__Well {
  border-left: 1px dashed;
  padding: 4px 15px 4px 4px;
}

.Popover-Estimate__Rigmove {
  padding: 4px;
}

.WellPopover__Toggles {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}
.WellPopover__ToggleBtn {
  flex: 1;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  background: $light_component_background;
  color: $black;
  border: 1px solid $black;
  transition: all 0.3s;
  &:hover{
    background: $black;
    color: $white;
  }
}

.WellPopover__Buttons {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid $black;
  display: flex;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  overflow: auto;
}
.WellPopover__Arrow {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 11px);
  transform: translateX(-50%);
  animation: opacity 0.9s;
  opacity: 1;

  &.hidden{
    opacity: 0.1;
  }
}

.WellPopover__Button {
  background: $light_component_background;
  border: 0;
  flex: 1;
  font-size: 15px;
  padding: 10px 0;
  cursor: pointer;
}

.WellPopover__Button--primary {
  background: $black;
  color: $white;
}
