.StatusModule {}

.StatusModule__part {
  border-bottom: 1px solid $light_gray;
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:last-of-type {
    border-bottom: none;
  }
}

.StatusModule__part--due {}

.StatusModule__part--numofreq {
  display: flex;
}

.StatusModule__part--numofreq-indicator {
  display: inline-block;
  margin: 5px 10px 0 0;

  span {
    background: #FC5644;
    color: $white;
    padding: 0;
    height: 26px;
    width: 26px;
    text-align: center;
    border-radius: 40px;
    display: block;
  }
}

.StatusModule__part--numofreq-text {}
@import 'InputMissingModule';