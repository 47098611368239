.placeHolderButton {
  border-radius: 10px;
}

#controlKit {
  z-index: 9999;
}

.overSpace {
  position: absolute;
  background-color: green;
  opacity: 0.5;
  z-index: 10;
  height: 50%;
  pointer-events: none;
}