.DarkModeSwitch {
  height: 25px;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    font-weight: bold;
  }

  span {
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 7px;

    .darkmode & {
      color: $white;
    }
  }
}
