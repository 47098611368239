.PanelForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 70px;
}

.PanelForm__RequestHeader {
  text-align: center;
  margin: 0 0 5px;
}

.PanelForm__Estimates {
  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .PanelForm__Estimate {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .PanelForm__InputPart {
      margin-top: 5px;
    }
  }

  .PanelForm__Estimate__Version {
    font-size: 15px;
  }
}

.PanelForm__Header,
.PanelForm__desc {
  text-align: center;
  width: 100%;
}

.PanelForm__Header {
  font-size: 20px;
}

.PanelForm__Header--bordered {}

.PanelForm__Header--with-search {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid $light_component_background;

  input {
    border: none;
    border-radius: 3px;
    padding: 5px 8px;
  }
}

.PanelForm__Header--wellupdate {
  text-align: left;
}

.PanelForm__desc {
  font-size: 15px;
}

.PanelForm__InputPart {
  text-align: center;
  margin: 20px 0;

  .PanelForm__flex{
    display: flex;
    max-width: 60%;
    margin: 0 auto;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; 
    }

    * {
      margin: 0 3px;
    }
  }

  &.PanelForm__InputPart--hidden {
    display: none;
  }
}

.PanelForm__inputlabel {
  display: block;
  margin: 0 0 5px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);

  &.left {
    width: 100%;
    text-align: left;
    float: left;
  }

  .PanelForm__inputlabel-text {
    &.rig {
      text-transform: capitalize;
    }
  }
  &:hover {
    .PanelForm__inputlabel-text {
      text-shadow: 0 0 1px $white;
    }
  }

  &.selected {
    background-color: white;

    .check-text {
      color: $black;
    }

    .PanelForm__inputlabel-text {
      color: $black;
    }
  }
}

.PanelForm__textinput {
  background: none;
  border-radius: $borderRadius;
  border: 1px solid $light_gray;
  padding: 8px 10px;
  color: $white;
  font-size: 14px;
  font-weight: 100;
  width: 55%;

  &.active {
    background-color: $white;
    color: $black;
  }

  &.PanelForm__textinput--textarea {
    width: 85%;
    min-height: 80px;
  }
}

.PanelForm__textarea {
  outline: none;
  resize: none;
  background: none;
  border-radius: $borderRadius;
  border: 1px solid $light_gray;
  padding: 8px 10px;
  color: $white;
  font-size: 14px;
  font-weight: 100;
  width: 100%;
  height: 150px;
}

.PanelForm__inputinfo {
  font-size: 11px;
  display: block;
  text-align: center;
  margin-top: 5px;
}

.Details-edit__part.right .DayPickerInput-OverlayWrapper {
  left: -78px;
}

.DayPickerInput-OverlayWrapper {
  z-index: 1000;
  height: 280px;
}

.DayPickerInput {
  width: 65%;
  color: $black;

  input {
    background: url("~../../assets/images/svg/icons/Calendar.svg") no-repeat 95% center;
    border-radius: $borderRadius;
    border: 1px solid #B2C2CC;
    padding: 8px 10px;
    color: $white;
    font-size: 14px;
    font-weight: 100;
    // width: 100%;
  }

  &.left {
    float: left;
  }

  &.active {
    input {
      background: url("~../../assets/images/svg/icons/Calendar_black.svg") no-repeat 95% center;
      background-color: $white;
      color: $black;
    }
  }
}

.PanelForm__InputPart--select {
  text-align: left;
}

.PanelForm__InputPart--checkbox {
  .PanelForm__inputlabel {
    border-radius: $borderRadius;
    border: 1px solid #B2C2CC;
    padding: 8px 10px;
    color: $white;
    font-size: 14px;
    font-weight: 100;
    position: relative;
    width: 55%;
    margin: 0 auto;
    cursor: pointer;

    &.PanelForm__inputlabel--checked {
      background: $white;
      color: $black;
    }

    &:not(:first-of-type) {
      margin-top: 10px;
    }

    &:hover {
      font-weight: bold;
    }
  }

  &.required .PanelForm__inputlabel {
    .validation-failed & {
      border-color: $validation-error-red;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check-indicator:after {
      display: block;
    }
  }

  .check-indicator {
    position: absolute;
    top: 8px;
    left: 8%;
    height: 16px;
    width: 16px;
    background: url("~../../assets/images/svg/icons/Circle_light.svg") no-repeat 100% center;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: url("~../../assets/images/svg/icons/CircleWithCheck.svg") no-repeat 100% center;
    }
  }
}

.PanelForm__inputheading {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 0 5px;
  font-weight: bold;
}

.PanelForm__InputPart--radio {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .PanelForm__inputlabel {
    display: inline-block;
    position: relative;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check-indicator:after {
      display: block;
    }
  }

  .check-indicator {
    position: absolute;
    top: 8px;
    left: 8%;
    height: 16px;
    width: 16px;
    background: url("~../../assets/images/svg/icons/Circle_light.svg") no-repeat 100% center;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: url("~../../assets/images/svg/icons/CircleWithCheck_light.svg") no-repeat 100% center;
    }
  }

  .PanelForm__inputlabel-text {
    margin: 0 20px 0 35px;
    position: relative;
    white-space: nowrap;
    top: 8px;
  }
}

.WellSelection {
  padding: 20px 10px;
  border: 1px solid #B2C2CC;
  border-radius: $borderRadius;
  margin: 0 0 10px;
  cursor: pointer;
}

.WellSelection--selected {
  background: $white;
  color: $black;
}

.WellSelection__name {
  @extend .FontStyle--Bold;
}
