.TextButton {
  cursor: pointer;
  margin: 10px 0;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }

  img {
    margin-left: 5px;
  }
}