.DNDContainer {}

.DNDC {
  z-index: 1;
  display: flex;
  position: absolute;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    opacity: 0;
    border: 1px solid black;
    transition: opacity 150ms ease-out;
  }

  &:hover {
    .conceptional {
      background: $color-conceptional-hover !important;

      &:after {
        background: linear-gradient(to right, colorWithOpacity($color-conceptional-hover, 0) 0%,colorWithOpacity($color-conceptional-hover, 1) 80%, colorWithOpacity($color-conceptional-hover, 0) 100%);
      }

      ~ .rigmove {
        background-color: $color-conceptional-hover !important;
      }

      .darkmode & {
        background: $color-conceptional-darkmode-hover !important;

        &::after {
          background: linear-gradient(to right, colorWithOpacity($color-conceptional-darkmode-hover, 0) 0%, colorWithOpacity($color-conceptional-darkmode-hover, 1) 80%, colorWithOpacity($color-conceptional-darkmode-hover, 1) 100%);
        }

        ~ .rigmove {
          background-color: $color-conceptional-darkmode-hover !important;
        }
      }
    }

    .occupied {
      background: $color-occupied-hover !important;

      &:after {
        background: linear-gradient(to right, colorWithOpacity($color-occupied-hover, 0) 0%, colorWithOpacity($color-occupied-hover, 1) 80%, colorWithOpacity($color-occupied-hover, 1) 100%);
      }

      ~ .rigmove {
        background-color: $color-occupied-hover !important;
      }

      .darkmode & {
        background: $color-occupied-darkmode-hover !important;

        &:after {
          background: linear-gradient(to right, colorWithOpacity($color-occupied-darkmode-hover, 0) 0%, colorWithOpacity($color-occupied-darkmode-hover, 1) 80%, colorWithOpacity($color-occupied-darkmode-hover, 1) 100%);
        }

        ~ .rigmove {
          background-color: $color-occupied-darkmode-hover !important;
        }
      }
    }

    .plan_and_select {
      background: $color-plan-and-select-hover !important;

      &:after {
        background: linear-gradient(to right, colorWithOpacity($color-plan-and-select-hover, 0) 0%, colorWithOpacity($color-plan-and-select-hover, 1) 80%, colorWithOpacity($color-plan-and-select-hover, 1) 100%);
      }

      ~ .rigmove {
        background-color: $color-plan-and-select-hover !important;
      }

      .darkmode & {
        background: $color-plan-and-select-darkmode-hover !important;

        &:after {
          background: linear-gradient(to right, colorWithOpacity($color-plan-and-select-darkmode-hover, 0) 0%, colorWithOpacity($color-plan-and-select-darkmode-hover, 1) 80%, colorWithOpacity($color-plan-and-select-darkmode-hover, 1) 100%);
        }

        ~ .rigmove {
          background-color: $color-plan-and-select-darkmode-hover !important;
        }
      }
    }

    .plan_for_execution {
      background: $color-plan-for-execution-hover !important;

      &:after {
        background: linear-gradient(to right, colorWithOpacity($color-plan-for-execution-hover, 0) 0%,colorWithOpacity($color-plan-for-execution-hover,1) 80%, colorWithOpacity($color-plan-for-execution-hover,1) 100%);
      }

      ~ .rigmove {
        background-color: $color-plan-for-execution-hover !important;
      }

      .darkmode & {
        background: $color-plan-for-execution-darkmode-hover !important;

        &:after {
          background: linear-gradient(to right, colorWithOpacity($color-plan-for-execution-darkmode-hover, 0) 0%, colorWithOpacity($color-plan-for-execution-darkmode-hover, 1) 80%, colorWithOpacity($color-plan-for-execution-darkmode-hover, 1) 100%);
        }

        ~ .rigmove {
          background-color: $color-plan-for-execution-darkmode-hover !important;
        }
      }
    }

    .engineering_and_design {
      background: $color-engineering-and-design-hover !important;

      &:after {
        background: linear-gradient(to right, colorWithOpacity($color-engineering-and-design-hover,0) 0%,colorWithOpacity($color-engineering-and-design-hover,1) 80%,colorWithOpacity($color-engineering-and-design-hover,1) 100%);
      }

      ~ .rigmove {
        background-color: $color-engineering-and-design-hover !important;
      }

      .darkmode & {
        background: $color-engineering-and-design-darkmode-hover !important;

        &:after {
          background: linear-gradient(to right, colorWithOpacity($color-engineering-and-design-darkmode-hover, 0) 0%,colorWithOpacity($color-engineering-and-design-darkmode-hover, 1) 80%, 0%,colorWithOpacity($color-engineering-and-design-darkmode-hover,1) 100%);
        }

        ~ .rigmove {
          background-color: $color-engineering-and-design-darkmode-hover !important;
        }
      }
    }

    .execution {
      background: $color-execution-hover !important;

      &:after {
        background: linear-gradient(to right, colorWithOpacity($color-execution-hover, 0) 0%,colorWithOpacity($color-execution-hover, 1) 80%, colorWithOpacity($color-execution-hover,1) 100%);
      }

      ~ .rigmove {
        background-color: $color-execution-hover !important;
      }

      .darkmode & {
        background: $color-execution-darkmode-hover !important;

        &:after {
          background: linear-gradient(to right, colorWithOpacity($color-execution-darkmode-hover,0) 0%,colorWithOpacity($color-execution-darkmode-hover,1) 80%,colorWithOpacity($color-execution-darkmode-hover,1) 100%);
        }

        ~ .rigmove {
          background-color: $color-execution-darkmode-hover !important;
        }
      }
    }

    .completed {
      background: $color-completed-hover !important;

      &:after {
        background: linear-gradient(to right, colorWithOpacity($color-completed-hover, 0) 0%,colorWithOpacity($color-completed-hover, 1) 80%, colorWithOpacity($color-completed-hover,1) 100%);
      }

      ~ .rigmove {
        background-color: $color-completed-darkmode-hover !important;
      }

      .darkmode & {
        background: $color-completed-darkmode-hover !important;

        &:after {
          background: linear-gradient(to right, colorWithOpacity($color-completed-darkmode-hover,0) 0%,colorWithOpacity($color-completed-darkmode-hover,1) 80%,colorWithOpacity($color-completed-darkmode-hover,1) 100%);
        }
      }

      ~ .rigmove {
        background-color: $color-completed-hover !important;
      }
    }

    .DNDC__menu-trigger {
      opacity: 1;
    }
  }

  &.hasActiveContextMenu {
    .DNDC__menu-trigger {
      opacity: 1;
    }
  }
}

.DNDC__Singlewell {
  position: absolute;
  // bottom: 95px;
  bottom: calc(30px + 5%);
  padding: 5px;
  transition: background-color 300ms ease, border-color 300ms ease, opacity 500ms ease, bottom 500ms ease, bottom 300ms ease 200ms;
  cursor: grab;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;

  &:before {
    content: '';
    position: absolute;
    width: 150px;
    height: 150px;
    top: -40px;
  }
  // > * {
  //   pointer-events: none;
  // }
  [disabled] {
    pointer-events: none;
  }

  &.DNDC__Singlewell--has-one-selected {
    opacity: 0.3;
  }

  &.DNDC__Singlewell--is-selected {
    opacity: 1;
  }

  &.DNDC__Singlewell--removed {
    background: #F5F8F9 !important;
    border: 1px solid #DCE1E5 !important;

    .DNDC__EstimateLabel,
    .DNDC__Timespan,
    .DNDC__Title {
      text-decoration: line-through;
    }

    .DNDC__Estimatewrapper {
      display: none !important;
    }
  }

  &.DNDC__Singlewell--extracted {
    background: #F5F8F9 !important;
    border: 1px solid #DCE1E5 !important;

    .DNDC__EstimateLabel,
    .DNDC__Timespan,
    .DNDC__Title {
      // text-decoration: line-through;
    }

    .DNDC__Estimatewrapper {
      display: none !important;
    }
  }

  &.DNDC__Singlewell--unsaved {
    border-style: dashed;
    border-width: 1px;

    &.DNDC__Singlewell--conceptional {
      border-color: $color-conceptional-border;
    }

    &.DNDC__Singlewell--plan_and_select {
      border-color: $color-plan-and-select-border;
    }

    &.DNDC__Singlewell--engineering_and_design {
      border-color: $color-engineering-and-design-border;
    }

    &.DNDC__Singlewell--plan_for_execution {
      border-color: $color-plan-for-execution-border;
    }

    &.DNDC__Singlewell--execution {
      border-color: $color-execution-border;
    }

    &.DNDC__Singlewell--completed {
      border-color: $color-completed-border;
    }

    &.DNDC__Singlewell--occupied {
      border-color: $color-occupied-border;
    }
  }

  .expanded & {
    bottom: 5px;
    transition-delay: 200ms;
  }

  .DNDC__EstimateLabel {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -65px, 0);
    opacity: 1;
    transition: all 200ms 300ms ease-out;
    font-size: 0.9rem;
  }

  .DNDC__Estimates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    height: 70px;
    transform: translate3d(0, -53px, 0);
    // background-color: pink;
    .DNDC__Estimates-error {
      position: absolute;
      text-align: center;
      top: 17px;
      left: 17px;
      color: $validation-error-red;
      pointer-events: none;
      font-size: 20px;
    }
  }

  .DNDC__Estimatewrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 43px;
    opacity: 0;
    transition: all 200ms 50ms ease-out;
    transform: translate3d(0, 35px, 0);
  }

  &:hover {
    .DNDC__EstimateLabel {
      transition: all 200ms 0 ease-out;
    }

    .DNDC__Estimates {
      .DNDC__Estimatewrapper {
        transform: translate3d(0, 0, 0);
        transition: all 200ms 450ms ease-out;
      }
    }
  }

  .DNDC__Estimate {
    font-size: 15px;
    text-align: center;
    pointer-events: all;
    cursor: text;
    padding: 3px 7px;
    transition: all 500ms ease;
    display: inline-block;
    border-radius: 15px;
    white-space: pre;
    border: 1px solid #CACFD0;
    background: transparent;
    text-align: center;

    &.focus,
    &:hover {
      background: $white;
    }
    &:disabled {
      opacity: 0.4;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .editable {
      padding-right: 5px;
    }
  }

  .DNDC__Title {
    font-weight: bold;
    font-size: 11px;
  }

  .DNDC__Selector {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 6px;
    left: 6px;
    cursor: default;
    opacity: 0;
    pointer-events: all;
    transition: opacity 300ms ease;
    z-index: 10;
  }

  &.DNDC__Singlewell--is-selected,
  &:hover {
    .DNDC__Selector {
      opacity: 1;
      transition: all 0.2s 200ms ease-out;

      path,
      svg {
        pointer-events: none;
      }
    }

    .DNDC__Estimates {
      .DNDC__Estimatewrapper {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .DNDC__EstimateLabel {
      transform: translate3d(-50%, -105px, 0);
      opacity: 0;
      transition: all 0.35s 0 ease-out;
    }

    .DNDC__actual{
      opacity: 1;
    }
  }

  .DNDC__DirtyWell {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -18px;
    width: 25px;
    height: 25px;
  }

  .DNDC__RemovedWell {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -18px;
    width: 25px;
    height: 25px;
  }

  .DNDC__menu-trigger {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 40px;
    height: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    cursor: pointer;
    z-index: 999;
    background: $white url('~../../assets/images/svg/icons/Gear.svg') no-repeat 5px center;
    padding: 2px 7px;
    border-radius: 15px;
    display: block;
    text-align: right;
    pointer-events: all;
  }
}

.DNDC__Singlewell--conceptional {
  background-color: $color-conceptional;

  &:hover {
    background-color: rgba(230, 216, 254, 0.6);
  }
}

.DNDC__Singlewell--plan_and_select {
  background-color: $color-plan-and-select;

  &:hover {
    background-color: rgba(255, 219, 245, 0.6);
  }
}

.DNDC__Singlewell--engineering_and_design {
  background-color: $color-engineering-and-design;

  &:hover {
    background-color: rgba(255, 236, 188, 0.6);
  }
}

.DNDC__Singlewell--plan_for_execution {
  background-color: $color-plan-for-execution;

  &:hover {
    background-color: rgba(253, 253, 196, 0.6);
  }
}

.DNDC__Singlewell--execution {
  background-color: $color-execution;

  &:hover {
    background-color: rgba(209, 255, 230, 0.6);
  }
}

.DNDC__Singlewell--completed {
  background-color: $color-completed;

  &:hover {
    background-color: rgba(234, 234, 234, 0.6);
  }
}

.DNDC__Singlewell--occupied {
  background-color: $color-occupied;

  &:hover {
    background-color: rgba(218, 240, 255, 0.6);
  }
}

.darkmode {
  .DNDC {
    &:hover {
      // outline: 2px solid rgba(255, 255, 255, 0.7);
    }
  }
}

.DNDC__Singlewell {
  .contextmenu {
    width: 143px;
    left: -10px;
    min-width: 0;
    top: 37px;
    z-index: 999;

    .arrow {
      left: auto;
      right: 17px;
      transform: rotate(90deg);
      border-left: 4px solid transparent;
      border-right: 4px solid #1c2226;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  .contextmenu--item-wrapper:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .contextmenu--item-wrapper:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .contextmenu--item-wrapper {
    z-index: 999;
    pointer-events: all;

    &:hover {
      background: $white;
      color: $black;
    }
  }
}

.DNDC__actual {
  position: absolute;
  top: -100px;
  left: 0;
  right: 0px;
  opacity: 0;
  transition: all 0.5s 0.5s;

  .DNDC__Estimate{
    max-width: 100%;
  }
}