.RoundedDropList {
  position: relative;
  display: block;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  top: -2px;
  width: 80px;
  height: 22px;
  border-radius: $borderRadius;
  border: 1px solid $light_gray;
  overflow: hidden;
  font-size: 14px;
  box-sizing: border-box;
  padding: 0 5px 15px;
  // margin-bottom: 27px;
  // option {
  //   margin-right: 5px;
  // }
  .arrow-icon {
    position: absolute;
    height: 5px;
    top: 50%;
    right: 10px;
  }

  select {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}