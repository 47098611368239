.Filter--Button {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 17px;
  border: $paddingSmall solid transparent;
  background-clip: content-box;
  opacity: 0.8;
  cursor: pointer;
  transition: all 200ms $easeInOut;

  .Filter--Button--Desc {
    position: absolute;
    opacity: 0;
    bottom: -30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
    text-align: center;
    transform: translate3d(-55px, 0, 0);
    transition: all 100ms $easeInOut;
  }

  &:after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 13px;
    box-shadow: 1px 1px 2px 1px rgba(100, 100, 100, 0.1);
    transition: all 200ms $easeInOut;
  }

  &.active {
    &:after {
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    }

    .Filter--Button--Desc {
      opacity: 0.4;
    }
  }

  &:hover {
    opacity: 1;
    z-index: 10;

    .Filter--Button--Desc {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.8);
    }

    &:after {
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    }
  }
}
