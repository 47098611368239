.Scheduler--Overview__Rig-List-Container {
  width: $rigListContainer--Width;
  position: relative;

  .space {
    height: $datesHeight - 25;
  }

  .Scheduler--Overview__Rig-List {
    padding-top: $paddingSmall;
    height: calc(100% - #{$datesHeight});
    box-sizing: content-box;
    overflow: scroll;
    width: 95%;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-corner {
      opacity: 0;
    }
  }
}

.Scheduler--Overview__AddRig {
  height: 50px;
  line-height: 50px;
  margin: -webkit-calc(10px / 2) 0 -webkit-calc(10px / 2) 0;
  margin: calc(10px / 2) 0 calc(10px / 2) 0;
}

.Scheduler__Overview__AddRigButton {
  background: transparent;
  border: 1px solid $black;
  text-align: center;
  position: relative;
  top: -1px;
  height: 40px;
  font-size: 20px;
  border-radius: $borderRadius;
  width: calc(100% - 18px);
  cursor: pointer;
  color: $black;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &.readonly {
    display: none;
  }
}

.Error__Message {
  background: $black;
  box-shadow: 0 10px 15px 0 rgba(0,0,0,0.20);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $red;
  font-size: 14px;
  margin: 0 auto;

  svg {
    margin: 0 10px 0 0;
  }
}