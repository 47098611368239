.Panel__Menu {
  display: flex;
  width: 100%;
  flex-basis: 60px;
  padding-left: $paddingBig;
  padding-right: $paddingBig;

  &.black {
    color: $black;

    .Panel__Menu--Button:after {
      background-color: $black;
    }
  }

  &.narrow {
    padding-left: $paddingXBig;
    padding-right: $paddingXBig;
  }

  .Panel__Menu--Button {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: default;
    opacity: 0.5;

    &.active {
      opacity: 1;
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }

    &.current {
      font-weight: bold;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $white;
      bottom: 0;
      left: 0;
    }

    &.active {
      &:after {
        content: '';
        height: 4px;
      }
    }
  }
}