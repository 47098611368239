.ErrorDisplay {
  position: absolute;
  left: 100%;
  top: calc(50% - 70px);
  color: white;
  width: 35px;
  cursor: pointer;
  transform: translate3d(-3px, 0, 0);
  transition: transform 150ms ease;
  z-index: -1;
  color: $light_panel_background;

  &:hover {
    transform: translate3d(0, 0, 0);
  }

  &.open {
    width: 250px;
    border: 1px solid #1C2226;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: white;
    height: auto;
    max-height: 50vh;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ErrorContainer {
    position: relative;
    padding: $paddingBig $paddingBig $paddingSmall;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 90%;
      bottom: 0;
      left: 5%;
      background-color: #8E9294;
    }
  }

  .ErrorDisplay_Header {
    position: relative;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    span {
      position: absolute;
      left: -16px;
      top: 0;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .error {
    font-size: 12px;
    color: #8E9294;
    margin-top: 6px;
    margin-bottom: 12px;
  }

  .ErrorDisplay__Header {
    height: 25px;
    border-bottom: 1px solid $light_panel_background;

    .ErrorDisplay__Header__Container {
      position: absolute;
      top: 3px;
      left: 6px;

      img {
        margin-right: 6px;
        transform: translate3d(0, 2px, 0);
      }

      span {
        color: $red;
      }
    }
  }

  .ErrorDisplay__close {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 6px;
    top: 4px;
  }

  &.animatable {
    animation: BounceRight 250ms 5;
  }
}