.Scheduler--Top {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  width: 100%;
  background: $light_backgroundGray;

  .darkmode & {
    background-color: #2D3032;
    color: #D4D6DA;
  }
}
@import 'SchedulerTop_Version';
@import 'SchedulerTop_Filter';
