.Overview {
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  width: 100%;
  // margin-top: 60px;
  backface-visibility: hidden;
}

.MastersModule {
  .Overview__ModuleHeading {
    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 3px;
      background-color: $black;
      width: 210%;
    }
  }
}

.Overview__Module {
  margin: 50px 20px 0;
  // width: 30%;
  // max-width: 250px;
  flex: 1;

  .Overview__Module__Header__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .Overview__ModuleHeading {
      flex: 1;
    }

    .RoundedDropList {
      flex: 1;
      height: 35px;
      margin-bottom: 26px;

      .darkmode & {
        select {
          background: transparent;
          color: white;
        }
      }
    }
  }

  .Overview__SubHeading {
    margin: 30px 0 15px;
    font-size: 1.1rem;
    line-height: 1.3rem;
    border-bottom: 1px solid $black;
    padding-bottom: 12px;

    span {
      cursor: pointer;

      &.bold {
        font-weight: bold;
      }
    }
  }

  .Overview__message {
    font-size: 0.9rem;
  }
}

.Overview__ModuleHeading {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 900;
  border-bottom: 3px solid $black;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
@import 'StatusModule';
@import 'NextScheduleModule';
@import 'MastersModule';