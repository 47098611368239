.Well--Booking {
  box-shadow: $bookingShadow;
}

.Well--Booking,
.Well-Booking__Sequence {
  position: absolute;
  // outline-offset: 3px;
  height: 20px;
}

.BufferTime,
.Well--Booking,
.Well-Booking__Sequence {
  min-width: 0;
  min-height: 20px;
  font-size: 12px;
  // box-shadow: 0 5px 8px 0 rgba(37,20,66,0.08);
  &.drag {
    &.dropReady {
      &:before {
        content: '';
        position: absolute;
        width: 5px;
        height: 20px;
        background-color: purple;
        top: 0;
        left: 0;
        opacity: 1;
        animation: blink182 0.3s 3;
      }
    }
  }
}
@keyframes blink182 {
  50% {
    opacity: 0;
  }
}

.Well-Booking__Sequence {
  position: absolute;
  overflow: visible !important;
  // box-shadow: $bookingShadow;
  .header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: $black;
    overflow: hidden;
    padding: 5px 10px;
    line-height: 100%;
    width: calc(100% - 10px);
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 10px;
    text-shadow: 1px 1px 4px $white;
  }

  .rigmove {
    display: inline-block;
    position: absolute;
    top: 0;
    line-height: 20px;
    border-right: 3px dashed;
    box-shadow: 0 5px 8px 0 rgba(37,20,66,0.08);
    background-color: black;
    color: white;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-weight: bold;
    z-index: 500;
    text-align: center;
  }

  .Well--Booking {
    overflow: visible;

    svg {
      position: absolute;
      top: -11px;
      right: -10px;
      width: 20px;
      text-shadow: 2px 2px 5px $black;
    }

    &:last-of-type {
      svg {
        display: none;
      }
    }
  }
}
// .Plotter--Row:hover {
//   .ContractOption,
//   .NoContract {
//     opacity: 1 !important;
//   }
// }

.Calendar--Entry {
  position: relative;
  color: black;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;

  &.FreeTime {
    position: absolute;
    height: 75%;
    max-height: 40px;
    pointer-events: none;
    background-color: $white;
    border-radius: 2px;
    z-index: 1;

    .darkmode & {
      background: #8D9198;
    }
  }

  &.NoContract {
    position: absolute;
    height: 54px;
    pointer-events: none;
    background-color: none;
    background: url("~../../assets/images/png/no_contract.png");
    border-radius: 0;
    z-index: 0;
    top: -4px;
    cursor: default;
    // opacity: 0.5;
    transition: opacity 0.2s ease-in;

    .darkmode & {
      opacity: 0.3;
      color: white;
    }
  }

  &.ContractOption {
    position: absolute;
    height: 54px;
    pointer-events: none;
    background-color: none;
    background: url("~../../assets/images/png/contract_option.png");
    border-radius: 0;
    z-index: 0;
    top: -4px;
    cursor: default;
    color: #6F7172;
    // opacity: 0.5;
    transition: opacity 0.2s ease-in;

    .darkmode & {
      opacity: 0.3;
      color: white;
    }
  }

  &.BufferTime {
    cursor: pointer;
  }

  &.hidden {
    opacity: 0.2;
  }

  &.selected {
    border-top: 1px solid $black;
    border-right: 1px solid $black;
    border-bottom: 1px solid $black;
    z-index: 999;
  }

  &.colliding {
    align-self: flex-start;

    &:nth-of-type(odd) {
      align-self: flex-end;
    }
  }
}

.filtered-out ~ .rigmove {
  display: none;
}

.Well--Booking {
  display: flex;
  justify-content: flex-start;
  border-left: 3px solid;
  box-sizing: border-box;
  justify-content: left;
  border-radius: 3px;

  img {
    pointer-events: none;
    margin-left: 4px;
    height: 8px;
    width: 6px;

    &:first-of-type {
      margin-left: 8px;
    }
  }

  &.element--allready--in--schedule {
    opacity: 1 !important;
    pointer-events: none;
  }

  &.hasRigmove {
    overflow: visible;
    height: 20px;
  }

  &.inactive {
    pointer-events: none;
  }

  &::after {
    content: ' ';
    height: 20px;
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: background 250ms ease-out;
  }

  &.conceptional::after {
    background: linear-gradient(to right, colorWithOpacity($color-conceptional, 0) 0%, colorWithOpacity($color-conceptional, 1) 80%, colorWithOpacity($color-conceptional, 1) 100%);
  }

  &.occupied::after {
    background: linear-gradient(to right, colorWithOpacity($color-occupied, 0) 0%, colorWithOpacity($color-occupied, 1) 80%, colorWithOpacity($color-occupied, 1) 100%);
  }

  &.plan_and_select::after {
    background: linear-gradient(to right, colorWithOpacity($color-plan-and-select, 0) 0%, colorWithOpacity($color-plan-and-select, 1) 80%, colorWithOpacity($color-plan-and-select, 1) 100%);
  }

  &.plan_for_execution::after {
    background: linear-gradient(to right, colorWithOpacity($color-plan-for-execution, 0) 0%, colorWithOpacity($color-plan-for-execution, 1) 80%, colorWithOpacity($color-plan-for-execution, 1) 100%);
  }

  &.engineering_and_design::after {
    background: linear-gradient(to right, colorWithOpacity($color-engineering-and-design, 0) 0%, colorWithOpacity($color-engineering-and-design, 1) 80%, colorWithOpacity($color-engineering-and-design, 1) 100%);
  }

  &.execution::after {
    background: linear-gradient(to right, colorWithOpacity($color-execution, 0) 0%, colorWithOpacity($color-execution, 1) 80%, colorWithOpacity($color-execution, 1) 100%);
  }

  &.completed::after {
    background: linear-gradient(to right, colorWithOpacity($color-completed, 0) 0%, colorWithOpacity($color-completed, 1) 80%, colorWithOpacity($color-completed, 1) 100%);
  }

  .darkmode & {
    &.conceptional::after {
      background: linear-gradient(to right, colorWithOpacity($color-conceptional-darkmode, 0) 0%, colorWithOpacity($color-conceptional-darkmode, 1) 80%, colorWithOpacity($color-conceptional-darkmode, 1) 100%);
    }

    &.occupied::after {
      background: linear-gradient(to right, colorWithOpacity($color-occupied-darkmode, 0) 0%, colorWithOpacity($color-occupied-darkmode, 1) 80%, colorWithOpacity($color-occupied-darkmode, 1) 100%);
    }

    &.plan_and_select::after {
      background: linear-gradient(to right, colorWithOpacity($color-plan-and-select-darkmode, 0) 0%, colorWithOpacity($color-plan-and-select-darkmode, 1) 80%, colorWithOpacity($color-plan-and-select-darkmode, 1) 100%);
    }

    &.plan_for_execution::after {
      background: linear-gradient(to right, colorWithOpacity($color-plan-for-execution-darkmode, 0) 0%, colorWithOpacity($color-plan-for-execution-darkmode, 1) 80%, colorWithOpacity($color-plan-for-execution-darkmode, 1) 100%);
    }

    &.engineering_and_design::after {
      background: linear-gradient(to right, colorWithOpacity($color-engineering-and-design-darkmode, 0) 0%, colorWithOpacity($color-engineering-and-design-darkmode, 1) 80%, colorWithOpacity($color-engineering-and-design-darkmode, 1) 100%);
    }

    &.execution::after {
      background: linear-gradient(to right, colorWithOpacity($color-execution-darkmode, 0) 0%, colorWithOpacity($color-execution-darkmode, 1) 80%, colorWithOpacity($color-execution-darkmode, 1) 100%);
    }

    &.completed::after {
      background: linear-gradient(to right, colorWithOpacity($color-completed-darkmode, 0) 0%, colorWithOpacity($color-completed-darkmode, 1) 80%, colorWithOpacity($color-completed-darkmode, 1) 100%);
    }
  }

  .wellTitle {
    text-align: left;
    padding-left: 5px;
    font-size: 10px;
    line-height: 20px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    font-weight: bold;
    pointer-events: none;
  }
}

.Suggestion {
  overflow: visible;
  border-radius: 3px;

  &.element--allready--in--schedule {
    top: 50px;
  }
}

.Suggestion--selected {
  border: 1px solid $black;
  border-left: none;
  z-index: 999;

  .Suggestion__Label {
    height: calc(100% + 2px);
  }
}

.Suggestion__Label {
  position: absolute;
  left: -90px;
  background: $black;
  z-index: 900;
  border-radius: 3px;
  color: $white;
  padding: 0 5px;
  height: 100%;
  width: 92px;
}