.PanelCampaign__Container {
  position: relative;
  display: flex;
  flex: 2;
  padding-bottom: 0;
  height: 100%;

  .Dragdrop__wells-container {
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: all;
    margin-left: 70px;
  }
}

.Dragdrop__Container {
  flex: 3;
  position: relative;
  background-color: $light_component_background;
  color: $black;
  // pointer-events: none;
  overflow-x: auto;
  overflow-y: hidden;

  .Estimate-marker {
    pointer-events: none;
  }

  &.expanded {
    .DNDC__Singlewell {
      .contextmenu {
        top: 10px;
      }
    }
  }
}
// .Estimate_Marker {

.Estimate-marker {
  position: absolute;
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  height: 65%;
  max-height: 280px;
  bottom: 0;
  border-left: 1px solid $black;
  border-top: 1px solid $black;
  border-right: 1px solid $black;
  border-top-left-radius: $paddingNormal;
  border-top-right-radius: $paddingNormal;
  position: absolute;
  background-color: transparent;
  margin-left: 50px;
  padding: $paddingBig $paddingBig 0;
  transition: opacity 300ms ease 200ms;

  .expanded & {
    opacity: 0;
  }
}

.Estimate-marker__datewrapper {
  // position: absolute;
  margin-top: -60px;
  position: absolute;
  text-align: center;
}

.Estimate-marker__label {
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 100;
  text-transform: uppercase;
}

.Estimate-marker__date {
  font-weight: bold;
}

.Estimate-marker__datewrapper--start-date {
  left: 0;
  transform: translate3d(-50%, -20px, 0);
}

.Estimate-marker__datewrapper--end-date {
  right: 0;
  transform: translate3d(50%, -20px, 0);
}

.Estimate-marker__estimate {
  position: absolute;
  margin-top: -33px;
  padding-left: $paddingNormal;
  padding-right: $paddingNormal;
  background-color: $light_component_background;
  text-transform: uppercase;
  color: $black;
  left: 50%;
  font-size: 1.1rem;
  transform: translate3d(-50%, 0, 0);
  text-align: center;

  .Estimate-marker__oldestimate {
    opacity: 0.5;
    text-decoration: line-through;
    display: block;
    text-align: center;
    font-size: 0.8rem;
    transition: all 500ms ease;
    position: absolute;
    top: -15px;
    width: calc(100% - 15px);
    text-align: center;
  }
}

.Estimate-marker__oldenddate {
  opacity: 0.5;
  text-decoration: line-through;
  // margin-right: 5px;
  display: block;
  font-size: 0.8rem;
  transition: all 500ms ease;
  transition-delay: 480ms;
  position: absolute;
  width: 100%;
}

.CampaignInfo__Wrapper {
  display: flex;
  flex: 5;
  display: none;
  padding: $paddingBig $paddingNormal;
}

.CampaignInfo__Container {
  flex: 6;

  &:first-of-type {
    flex: 2;
    padding-right: 20px;
  }

  .PanelForm__InputPart {
    text-align: left;
    margin: 0 0 20px;
  }

  .RoundedDropDown {
    color: $black;
    width: auto;
    border: none;

    .RoundedDropDown--Elements--Container {
      border: none;
      left: 0;
      width: calc(100%);
    }
  }

  .PanelForm__textinput {
    width: auto;
  }

  .Container__part_1 {
    flex: 1;
    flex-direction: column;

    h1 {
      font-size: 25px;
      @extend .FontStyle--Bold;
    }

    .info {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }

  .Container__part_2 {
    display: flex;
    flex: 4;
    flex-direction: row;
    justify-content: center;
  }

  .Part {
    flex: 4;

    &.onethird {
      flex: 2;
      padding-right: 20px;
    }

    .PanelForm__InputPart {
      margin: 0 0 20px;
      max-width: 80%;
    }

    .DayPickerInput {
      width: 100%;
      display: inline;

      input {
        width: 100%;
      }
    }

    .PanelForm__inputlabel {
      text-align: left;
    }
  }

  .Comment {
    position: relative;
    width: 100%;
    height: 80%;
    border: 1px solid $white;
    border-radius: $paddingSmall;

    .Comment__Header {
      position: absolute;
      background-color: $black;
      top: -$paddingNormal;
      left: 0;
      font-size: 10px;
      padding-right: $paddingSmall;
    }

    .Comment__Text {
      padding: $paddingNormal;
      background: $black;
      color: $white;
      border: none;
      width: 100%;
      height: 100%;
      resize: none;
      font-size: 15px;
    }
  }
}
@keyframes animatePanel {
  0% {
    flex-basis: auto;
    height: 100px;
  }
  // 30%   { flex-basis: auto;height: 0; }
  100% {
    flex-basis: auto;
    height: 350px;
  }
}

.Details__Container {
  background: $black;
  // flex-basis: 100px;
  height: 110px;
  padding: 0 15px;
  display: flex;
  position: relative;
  transition: height 300ms ease;
  max-height: 500px;
  transition-delay: 180ms;

  &.expanded {
    height: calc(100% - 30px);
  }

  .RoundedDropDown {
    color: $white;
    background: transparent;
    min-width: 230px;
    width: 100%;
  }

  .RoundedDropDown__label {
    display: flex;
    align-items: center;
    height: 33px;

    span {}
  }

  .RoundedDropDown__circle {
    position: relative;
    top: 2px;
  }

  .RoundedDropDown--Elements--Container {
    background: $black;
    bottom: 25px;
    top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid #B2C2CC;
    border-bottom: none;
  }
}

.Details__edit-btn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background: $black;
  color: $white;
  font-size: 10px;
  text-transform: uppercase;
  display: block;
  width: 170px;
  height: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  padding: 5px 5px 5px 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;
  opacity: 0;
  cursor: pointer;
  z-index: 999;

  &.active {
    top: -25px;
    opacity: 1;
  }

  .arrow-icon {
    position: absolute;
    left: 30px;
    top: 10px;

    .left-bar,
    .right-bar {
      &:after {
        width: 9px;
      }
    }

    .right-bar {
      top: 2px;
      left: 9px;
    }
  }
}

.Details__status {
  // overflow: hidden;
  flex: 1.4;
}

.Details__status-name {
  padding: 20px 0 0;
}

.Details__summary {
  padding: 15px 0 15px 100px;
  // transition: opacity 400ms ease 200ms;
  &.hidden {
    // animation: fadeOutAndRemove 400ms normal ease;
    // animation-iteration-count: 1;
    display: none;
  }
}

.Details__part {
  display: flex;
  margin-bottom: 5px;
}

.Details__label {
  color: #8D9198;
  width: 70px;
}

.Details__value {
  white-space: nowrap;
}

.Details__welltitle {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

.Details__comment {
  color: $white;
  margin-left: 50px;
  padding: 15px 0;
  width: 100%;
  min-height: 100px;

  &.left {
    position: relative;
    margin-left: 0;
    height: calc(100% - 300px);

    .Details__label {
      font-size: 10px;
      font-weight: normal;
      color: #8D9198;
    }

    > div {
      padding: 0;
    }

    > textarea {
      margin: 4px 0;
      color: #8D9198;
      font-size: 12px;
      height: calc(100% - 30px);
    }
  }

  &.hidden {
    display: none;
  }

  .Details__label {
    color: $white;
    width: auto;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding-left: 6px;
  }

  .Details__value {
    white-space: normal;
    font-size: 0.9rem;
    padding: 6px;
    background: transparent;
    border: 1px dashed $black;
    border-radius: 5px;
    transition: all 500ms ease;
    border: 1px dashed #46484B;
    min-width: 200px;
    margin: 4px;
    color: $white;
    width: 100%;
    min-height: 60px;
    resize: none;

    &:hover {
      border: 1px dashed #8D9198;
    }

    &.active {
      background-color: $white;
      color: $black;
    }
  }
}

.Details__navigation {
  // transition: opacity 400ms ease 300ms;
  margin-top: 20px;

  &.hidden {
    // animation: fadeOutAndRemove 400ms normal ease 1s;
    display: none;
  }
}

.Details-navigation__item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  // border-bottom: 1px solid #8D9198;
  color: #8D9198;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #8D9198;
    transition: transform 250ms ease;
    transform: translate3d(0, 3px, 0);
  }

  &:hover {
    &:after {
      transform: translate3d(0, 0, 0);
    }
  }

  &.active {
    .Details-navigation__title {
      font-weight: 900;
    }
  }

  &--disabled{
    font-style: italic;
    align-items: center;
    cursor: default;
  }
}

.Details-navigation__title {
  font-size: 0.9rem;

  .active & {
    color: $white;
  }
}
.Details-navigation__info{
  font-size: 0.7rem;
}

.Details-navigation__completion {
  font-size: 0.8rem;

  .active & {
    color: $white;
  }
}

.Details__expanded {
  // transition: opacity 400ms ease 1s;
  flex-direction: column;
  overflow-x: auto;

  &:empty {
    animation: fadeOutAndRemove 400ms normal ease 1s;
  }

  h1 {
    margin: $paddingBig $paddingNormal;
    font-weight: 900;
    font-size: 20px;
    text-decoration: underline;
    text-align: left;
    width: 100%;
    text-indent: $paddingBig;
  }
}

.Details-edit {
  flex: 5;
  overflow-y: auto;
  // margin-top: 97px;
  margin-top: 91px;
  // display: flex;
  // flex-direction: column;
}

.Details-edit__section:nth-child(even) {
  background-color: #1a1a1a;
}

.Details-edit__section {
  // flex: 1;
  padding: 10px 20px;
  height: 100%;
  min-width: 700px;
  // display: flex;
  // flex-direction: row;
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 15px;
  }
}

.Details-edit__wrapper {
  // flex: 1;
  // padding: 20px;
  display: flex;
  flex-direction: row;
}

.Details-edit__part {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid $light_component_background;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 330px;

  &:last-of-type {
    border-right: none;
    padding-right: 0;
  }

  &:first-of-type {
    // border-right: none;
    // padding-right: 20px;
  }
  @for $i from 1 through 10 {
    > div:nth-child(#{$i}) {
      z-index: 10 - $i;
    }
  }
}

.Details-edit__horiz{

  width: 100%;

  h4 {
    margin: 0 0 5px;
    font-weight: bold;
  }

  .Details-edit__horiz-inner{
    display: flex;
    flex-direction: row;
  }

  .Details-edit__inputwrapper{
    margin-right: 4px;
    width: 100%;
  }
}

.Details-edit__helptext {
  margin-top: 50px;

  .validation-failed & {
    color: $validation-error-red;
  }
}

.Details-edit__inputwrapper {
  position: relative;
  border: 1px solid #B2C2CC;
  border-radius: 5px;
  background: $black;
  padding: 4px 8px;
  margin: 0 0 5px;
  width: 100%;

  &:hover{
    // border: 2px solid #B2C2CC;
    background-color: rgba(245, 248, 249, 0.15);
  }

  &.active label {
    transform: translate(0, -1px) scale(0.7);
    font-weight: bold;
  }

  &:focus-within label{
    transform: translate(0, -1px) scale(0.7);
    font-weight: bold;
    // color: white;
  }

  &--no-bottom-margin{
    margin-bottom: 0;
  }

  &.has-content {
    background: $white;

    label {
      transform: translate(0, -1px) scale(0.7);
      font-weight: bold;
    }
  }
  &.readonly {
    background: #dedede;

    label {
      transform: translate(0, -1px) scale(0.7);
      font-weight: bold;
    }
  }
}

.Details-edit__info-section{
  margin: 10px 0 30px;
  flex: 1;

  > span{
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin: 0 0 2px 0;
  }
}

.Details-edit__selectwrapper {
  width: 100%;
  margin: 0 0 5px;
  text-align: center;
  position: relative;

  .Select__Dropdown {
    width: 100%;
  }

  .Select__Dropdown__Elm__value-container--has-value {
    padding: 0 8px;
  }

  .Select__Dropdown__Elm__single-value {
    transform: translateY(10%);
    margin-left: 1px;
    justify-self: flex-start;
  }
}

.Details-edit__selectlabel {
  position: absolute;
  left: 10px;
  top: 3px;
  right: 0;
  font-weight: bold;
  color: #1c2226;
  z-index: 999;
  text-align: left;
  font-size: 9.5px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

.Details-edit__checkboxwrapper {
  width: 100%;
  margin: 0 0 5px;

  .PanelForm__InputPart {
    margin: 0;
  }

  .PanelForm__InputPart--checkbox .PanelForm__inputlabel {
    width: 100%;
  }
}

.Details-edit__inputlabel{
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 18px;
  text-transform: uppercase;
  position: absolute;
  transform: translate(0, 7px) scale(1);
  transform-origin: top left;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
  color: hsl(0,0%,50%);

  .has-content & {
    color: $black;
  }
}

.Details-edit__inputinfo{
  font-size: 12px;
  color: hsl(0,0%,50%);
  font-style: italic;

  .has-content & {
    color: $black;
  }
}

.Details-edit__input {
  border: 0;
  outline: none;
  font-size: 14px;
  padding: 9px 0 4px;
  width: 100%;
  background: transparent;
  resize: none;

  &[type=number]::-webkit-outer-spin-button,
  &[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number]{
    -moz-appearance:textfield;
  }
}

.Details-edit__sublabels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.Details-edit__sublabel {
  font-size: 12px;
  text-transform: uppercase;

  &:first-of-type {
    text-align: left;
  }

  &:last-of-type {
    text-align: right;
  }
}

.Details-edit__checkboxwrapper {
  width: 100%;
  margin: 0 0 20px;

  .PanelForm__InputPart {
    margin: 0;
  }

  .PanelForm__InputPart--checkbox .PanelForm__inputlabel {
    width: 100%;
  }
}

@keyframes fadeOutAndRemove {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    display: block;
  }

  100% {
    opacity: 1;
  }
}

.radio-input {
  margin: 0 0 5px;
  width: 100%;

  &.radio-input--horizontal {
    display: flex;
    align-items: center;
  }
}

.radio-input__mainlabel {
  text-align: center;
  margin: 0 0 3px;
  display: block;

  .radio-input--horizontal & {
    flex: 1;
    text-align: left;
  }
}

.radio-input__sublabel {
  font-size: 12px;
  display: block;

  .Details-edit__checkboxwrapper & {
    text-align: center;
    margin-top: 4px;
  }

  .required & {
    .validation-failed & {
      color: $validation-error-red;
    }
  }
}

.radio-input__optionwrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;

  .radio-input--horizontal & {
    flex: 1;
    width: auto;
  }

  .radio-input--horizontal & {
    flex-direction: row;
  }
}

.radio-input__option {
  position: relative;
  flex: 1;
  border-left: 1px solid #B2C2CC;
  border-right: 1px solid #B2C2CC;
  border-radius: 5px;
  background: $black;
  padding: 6px 8px 4px 40px;

  .radio-input--horizontal & {
    border: none;
    text-align: center;
    padding: 9px;
    border: 1px solid #B2C2CC;
    border-radius: 0;

    &.selected {
      background: $white;
      color: $black;
    }
  }

  &:hover {
    .radio-input__label {
      font-weight: bold;
    }
  }

  &.selected {
    .check-indicator {
      background: url("~../../assets/images/svg/icons/CircleWithCheck_light.svg") no-repeat 100% center;
    }
  }

  &:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top: 1px solid #B2C2CC;

    .radio-input--horizontal & {
      border-right: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: 1px solid #B2C2CC;

    .radio-input--horizontal & {
      border: 1px solid #B2C2CC;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .check-indicator {
    position: absolute;
    top: 8px;
    left: 8%;
    height: 16px;
    width: 16px;
    background: url("~../../assets/images/svg/icons/Circle_light.svg") no-repeat 100% center;

    .radio-input--horizontal & {
      display: none;
    }
  }
}

.radio-input__label {
  cursor: pointer;
  display: block;

  .radio-input--horizontal & {
    text-transform: uppercase;
  }
}

.radio-input__radio {
  display: none;
}

.date-input {
  .PanelForm__InputPart {
    margin: 0;
  }

  .DayPickerInput {
    width: 100%;
    border: 0;

    input {
      width: 100%;
      border: 0;
      padding: 9px 0 4px;
    }
  }
}