@import 'reset';
@import 'fonts';
@import 'variables';
@import 'react-select/react-select';
@import "../../../node_modules/react-day-picker/lib/style";

body,
html {
  height: 100vh;
  overflow: hidden;
  scroll-behavior: smooth;
}

body {
  color: $black;
  // position: relative;
  background-color: $light_backgroundGray;
  @extend .FontStyle--Regular;

  > div {
    color: $black;
  }

  * {
    @extend .FontStyle--Regular;
    user-select: none;
  }

  .WellPopover__ContentText,
  .WellPopover__DurationLabel,
  .WellPopover__DurationText,
  h1,
  h2,
  h3,
  input,
  p,
  textarea {
    user-select: text;
  }

  *:focus {
    outline: none;
  }

  .RootContainer {
    display: flex;
    position: relative;
    max-height: 100%;
    height: 100%;
    flex-direction: column;
  }

  .ToolBarContainer {
    width: $ToolBarWidth_open;
    height: 100vh;
    box-shadow: 1px 0 10px 0 rgba(0,0,0,0.04), 5px 0 10px 0 rgba(0,0,0,0.08);
    z-index: $toolbarIndex;
    transition: transform $fastTransition $easeInOut;
    transform: translate3d(0, 0, 0);
    z-index: 200;
    // &.open {
    //   transform: translate3d(0, 0, 0);
    //   // flex-basis: $ToolBarWidth_open;
    // }
    &.hidden {
      // flex-basis: 0;
      overflow: hidden;
    }
  }

  .ViewContainer {
    position: relative;
    flex-grow: 1;
    // margin-top: 60px;
    backface-visibility: hidden;
    overflow: hidden;
  }

  .narrow {
    margin: 0 0 20px;
  }
}

.chevron::before {
  border-style: solid;
  border-width: 0.09em 0.09em 0 0;
  content: '';
  display: inline-block;
  height: 0.8em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.8em;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: -0.3em;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.ignore-events {
  pointer-events: none;
}

.text__center {
  text-align: center;
}

.svg {
  pointer-events: none;
}

.flex {
  display: flex;
}

.align__self {
  align-self: center;
}

.marginTop {
  margin-top: $paddingNormal;
}

.ROW {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 50px);
  padding-top: 2px;

  > select {
    background-color: transparent;
    height: 24px;
    width: 80px;
    padding: 4px 8px;
  }
}

.noselect {
  border: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.darkmode .Scheduler--Overview,
.darkmode .Scheduler--Top {
  transition: background-color 0.2s;
}

.PUSH_BOTTOM {
  margin-bottom: 5vh;
}

.CENTER_TEXT {
  text-align: center;
  width: 100%;
}

.PADDING_TOP {
  padding-top: 1.5vh;
}

.readonly {
  opacity: 0.5;
  cursor: auto !important;
}
@import 'debug';
@import 'animations';
@import 'components/components';
@import 'pages/pages';
@import 'containers/containers';
@import 'daypicker';
@import 'iOS_FIX';
@import 'IE11_FIX';