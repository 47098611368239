.DropDate {
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #B2C2CC;
  z-index: 100;
  // transition: transform 100ms;
  pointer-events: none;

  .DateInText {
    color: #B2C2CC;
    position: absolute;
    transform: translate3d(5px,-10px, 0);
    left: 0;
  }
}